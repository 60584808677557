import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import { useState} from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector } from "react-redux";
import { getISOCurrentDate } from "../../../utils/timeUtils";

export default function ValidatorCheckoutApprovalModal({
  show,
  handleClose,
  product,
  setProduct,
  currentPP,
  refetch,
  setAlert,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [checkoutPackageInfo, setCheckoutPackageInfo] = useState({});
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const user = useSelector((state) => state.user.value);
  const [notes, setNotes] = useState([]);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setFile({ ...file, [e.target.name]: e.target.files[0] });
      setFileName(
        "CCEVS-VR-VID" +
          product.v_id.toString() +
          "-" +
          new Date().getFullYear().toString()
      );
    } else {
      setCheckoutPackageInfo({
        ...checkoutPackageInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdateEvaluatorCheckoutFiles = () => {
    axios.put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/update_evaluator_checkout_files/`,
        {pid: product.product_id},
        {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              'X-CSRFToken': csrfToken, 
            },
        }
    ).then(response => {
        console.log('files updated')
    }).catch(error => {
        console.log(error)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //adding validator checkout out and posting to file endpoint
    const submittedData = new FormData();
    submittedData.append("active", JSON.stringify(true))
    submittedData.append("file", file.file);
    submittedData.append("file_type_id", product?.product_id)
    submittedData.append("file_type", "product");
    submittedData.append("file_source_type", "Validator Check Out");
    submittedData.append("file_display_name", "Final Draft Validation Report");
    submittedData.append("uploaded_on", getISOCurrentDate())
    submittedData.append("private", JSON.stringify(false));
    submittedData.append("file_name", fileName);
    submittedData.append("file_label", fileName);

    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        let resubmissionCounter = currentPP.resubmission_counter;
        if (resubmissionCounter === null) {
          resubmissionCounter = 0;
        } else {
          resubmissionCounter += 1;
        }
        const note = "Product Approved";
        const submittedBy = user.id;
        const noteType = "Product";
        const noteTypeId = product.product_id;
        createNote(note, submittedBy, noteType, noteTypeId);
        handleUpdateEvaluatorCheckoutFiles();
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
            {
              product: product.product_id,
              progress_point: 7,
              status: "Ready for Release",
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then((response) => {   
            axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
              {
                product: product.product_id,
                progress_point: 6,
                status: "Completed",
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              }
            )
            .then((response) => {
              refetch();
              handleClose();
              setAlert({
                variant: "success",
                message: "Progress Point Successfully Approved",
              });
            })
            .catch((error) => console.log(error));
            })
          .catch((error) => {
            console.log("error updating progress point", error);
          });
      })
      .catch((error) => {
        console.log("error updating product", error);
      });
  };

  const createNote = async (note, submittedBy, noteType, noteTypeId) => {
    const newNotes = notes.slice();
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          submitted_by: submittedBy,
          note_type: noteType,
          note_type_id: noteTypeId,
          submitted_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => newNotes.push({ ...response.data }))
      .catch((error) => alert("Unable to add note: " + error));
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit} id="validator-checkout-approval-form">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <h3 className="text-bright-navy m-0">CHECK OUT PACKAGE</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-5">
            <Row>
              <Col sm={3} className="mt-auto">
                <h5>Validation Report: </h5>
              </Col>
              {/* <Col sm={3}>
                <Form.Group controlId="file_public_status">
                  <Form.Label className="small text-secondary">
                    Document Visibility
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    onChange={handleChange}
                  >
                  <option value=""></option>
                    <option value="Public">Public</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
              <Col sm={9}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach File
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {Object.keys(file).length > 0 && (
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={5} lg={3} className="border-bottom">
                    <p className="m-0 p-2">Validation Report</p>
                  </Col>
                  <Col xs={5} lg={5} className="border-bottom">
                    <p className="m-0 p-2">{fileName}</p>
                  </Col>
                  <Col xs={2} lg={1} className="m-0 p-2 border-bottom">
                    <Button
                      variant="outline-primary"
                      className="attachment-remove"
                      onClick={() => setFile({})}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="rounded-pill"
            type="submit"
            disabled={Object.keys(file).length === 0}
          >
            Save/Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
