import {
  Row,
  Col,
  OverlayTrigger,
  Stack,
  Popover,
  Button,
  Form,
  Alert
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useState, useCallback, useEffect, React } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import FileDownload from "@mui/icons-material/FileDownload";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ReactSelect from "../../../ReactSelect";
import "./ProductDetailsHeaderInfo.css";
import csfcOptions from "../ProductForm/CSFCClaimOptions";
import Select from "react-select";
import { calculateDaysInProgress, calculateDaysInProgressProduct } from "../../../../utils/timeUtils";

export default function ProductDetailsHeaderInfo({
  product,
  projpps,
  maintenances,
  setShow,
  isValidator,
  user,
  certificate,
  // updateCertificate,
  downloadFile,
  showModal,
  showValidatorModal,
  validatorFinalReview,
  amView,
  refresh,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProductInfo, setUpdatedProductInfo] = useState({});
  const [updatedVendorInfo, setUpdatedVendorInfo] = useState({});
  const [updatedLabInfo, setUpdatedLabInfo] = useState({});
  const [updatedAmInfo, setUpdatedAmInfo] = useState({});
  const [allPps, setAllPps] = useState([]);
  const [productPps, setProductPps] = useState([]);
  const [editedPps, setEditedPps] = useState([]);
  const [alertInfo, setAlertInfo] = useState({message: "", variant: ""})
  const [csfcComponents, setCsfcComponents] = useState([]);
  const [editedCsfcComponents, setEditedCsfcComponents] = useState([]);

  const getDaysInProgress = () => {
    if (product?.kicked_off_date) {
      if (product?.posted_date) {
        let daysInProgress = calculateDaysInProgress(product?.kicked_off_date, product?.posted_date);
        return daysInProgress;
      } else if (product?.termination_date) {
        let daysInProgress = calculateDaysInProgress(product?.kicked_off_date, product?.termination_date);
        return daysInProgress;
      } else {
        let daysInProgress = calculateDaysInProgressProduct(product);
        return daysInProgress;
      }
    } else {
      return 0;
    }
  }

  const latestApprovedMaintenance = maintenances
    ?.filter((maintenance) => maintenance.status === "Approved")
    ?.sort((a, b) =>
      new Date(a.completion_date).getTime() <
      new Date(b.completion_date).getTime()
        ? 1
        : -1
    )[0];

    const getAMColumnDetails = () => {
      if(product?.current_assurance_maintenance !== null){
        return (
          <Link
            to={`assurancemaintenance/${product?.current_assurance_maintenance?.maintenance_id}`}
            relative="route"
            className="text-decoration-underlined"
          >
            {product?.current_assurance_maintenance?.title}
          </Link>
        )
      } else if (latestApprovedMaintenance){
        return (
          <Stack>
            <Link
                to={`assurancemaintenance/${latestApprovedMaintenance?.maintenance_id}`}
                relative="route"
                className="text-decoration-underlined"
            >
                {latestApprovedMaintenance?.title}
            </Link>
            <p>
                {latestApprovedMaintenance?.completion_date ?
                    moment.utc(latestApprovedMaintenance?.completion_date).format("MM/DD/YYYY")
                    : "TBD"
                }
            </p>
          </Stack>
        )
      }
    }

  const editViewButtons = () => {
    if (
      isValidator?.is_validator &&
      (permissions.is_senior || permissions.role_type === "NIAP") &&
      product?.status === "Pre-Evaluation" &&
      product?.kickoff_status === "Ready for Recommendation" &&
      (
        product?.rejected_rationale !== null &&
        product?.rejected_rationale !== ""
      )
    ) {
      return (
        <Col className="px-0">
          <Button
            variant="warning"
            className="me-3 mb-2 rounded-pill"
            onClick={() => showValidatorModal()}
          >
            Update Kickoff Recommendation
          </Button>
        </Col>
      );
    } else if (
      isValidator?.is_validator &&
      (permissions.is_senior || permissions.role_type === "NIAP") &&
      product?.status === "Pre-Evaluation" &&
      product?.kickoff_status === "Ready for Recommendation"
    ) {
      return (
        <Col className="px-0">
          <Button
            variant="warning"
            className="me-3 mb-2 rounded-pill"
            onClick={() => showValidatorModal()}
          >
            Kickoff Recommendation
          </Button>
        </Col>
      );
    } else if (
      user?.user_role?.role_type === "NIAP" &&
      product?.status === "Pre-Evaluation" &&
      product?.kickoff_status === "Recommended for Kickoff"
    ) {
      return (
        <Col className="px-0">
          <Button
            variant="warning"
            className="me-3 mb-2 rounded-pill"
            onClick={() => showModal()}
          >
            Kickoff Recommendation by Validator
          </Button>
        </Col>
      );
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "vendor_name") {
      const updatedVendorInfo = {
        name: e.target.value,
      };
      setUpdatedVendorInfo(updatedVendorInfo);
    } else if (e.target.name === "lab_name") {
      const updatedLabInfo = {
        name: e.target.value,
      };
      setUpdatedLabInfo(updatedLabInfo);
    } else if (e.target.name === "am_date") {
      const updatedAmInfo = {
        completion_date: e.target.value,
      };
      setUpdatedAmInfo(updatedAmInfo);
    } else if (e.target.name === "protection_profile") {
      const protectionProfiles = [...editedPps];
      if (!protectionProfiles.includes(e.target.value)) {
        protectionProfiles.push(e.target.value);
      }
      setEditedPps(protectionProfiles);
    } else if (e.target.name === "csfc_components") {
      setEditedCsfcComponents([...e.target.value])
    } else if (e.target.name === "tech_type") {
      let values = []
      for (let i = 0; i < e.target.selectedOptions.length; i++) {
          values.push(e.target.selectedOptions[i].value)
      }
      setUpdatedProductInfo({
        ...updatedProductInfo,
        [e.target.name]: values?.join(","),
      });
    } else {
      setUpdatedProductInfo({
        ...updatedProductInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSave = async () => {
    let saveSuccessful = false;
    if (Object.keys(updatedVendorInfo).length > 0) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${product?.vendor_id?.org_id}/`,
          { ...updatedVendorInfo },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          saveSuccessful = true;
        })
        .catch((error) => console.log("Unable to update product: " + error));
    }
    if (Object.keys(updatedLabInfo).length > 0) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${product?.assigned_lab?.org_id}/`,
          { ...updatedLabInfo },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          saveSuccessful = true;
        })
        .catch((error) => console.log("Unable to update product: " + error));
    }
    if (Object.keys(updatedAmInfo).length > 0) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${latestApprovedMaintenance?.maintenance_id}/`,
          { ...updatedAmInfo },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          saveSuccessful = true;
        })
        .catch((error) => console.log("Unable to update product: " + error));
    }
    if(csfcComponents.length > 0){
      csfcComponents.forEach(component => {
        // Check if the 'value' of 'component' does NOT exist in 'editedCsfcComponents'
        const isRemoved = !editedCsfcComponents.some(editedComponent => editedComponent.value === component.component);
        if (isRemoved) {
          axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}cs/proj/remove_cs_proj/`,
            {
              csid: component.cscid,
              pid: product.product_id
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then((response) => {
            console.log('Component removed')
          })
          .catch((error) => console.log("Unable to update product: " + error));
        }
      });
    }

    if(editedCsfcComponents.length > 0){
      editedCsfcComponents.forEach(obj1 => {
          axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}cs/proj/`,
            {
              pid: product.product_id,
              component: obj1.value,
              component_url: obj1.url
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            }
          )
          .then((response) => {
            console.log('Component added')
          })
          .catch((error) => console.log("Unable to update product: " + error));
      });
      }  

    if (Object.keys(updatedProductInfo).length > 0 || editedPps.length > 0) {
      let pps = []
      if (editedPps.length > 0) {
        editedPps.forEach((pp) => {
          pps.push(pp);
        });
        updatedProductInfo["protection_profile"] = pps
      }
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
          "status" in updatedProductInfo && updatedProductInfo.status === "Archived" ?
            {...updatedProductInfo, sunset_date:new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset()))} : 
            {...updatedProductInfo},
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          }
        )
        .then((response) => {
          saveSuccessful = true;
        })
        .catch((error) => console.log("Unable to update product: " + error));
    }
    if(saveSuccessful){
      setAlertInfo({message:"New Product Information Succesfully Saved", variant:"success"})
      refresh();
      loadCSFCComponents();
    }
    setIsEditing(false);
  };

  const techTypeOptions = [
    "Application Software",
    "Anti-Virus",
    "Biometrics",
    "BIOS Update",
    "Certificate Authority",
    "DBMS",
    "Degaussing Equipment",
    "Email Client",
    "Encrypted Comm Devices",
    "Encrypted Storage",
    "Enterprise Security Management",
    "Firewall",
    "Guard",
    "Hardware Platform and Components",
    "Key Recovery",
    "Miscellaneous",
    "Mobility",
    "Mobile Code",
    "Multi Function Device",
    "Multiple Domain Solution",
    "Network Access Control",
    "Network Device",
    "Network Encryption",
    "Network Interface Card",
    "Network Management",
    "Network Separator",
    "Network Switch",
    "Operating System",
    "Peripheral Switch",
    "PKI/KMI",
    "Redaction Tool",
    "Remote Access",
    "Retransmission Device",
    "Router",
    "Secure Messsaging",
    "Sensitive Data Protection",
    "Sharing Switch",
    "SIP Server",
    "Smart Card",
    "System Access Control",
    "Tokens",
    "Traffic Monitoring",
    "USB Flashdrive",
    "Virtual Private Network",
    "Virtualization",
    "VoIP",
    "Web Browser",
    "Web Server",
    "Wireless LAN",
    "Wireless Monitoring",
    "Wireless PAN"
  ];

  const statusOptions = [
    "Draft",
    "Proposed",
    "Pre-Evaluation",
    "In Progress",
    "Finalizing",
    "Certified",
    "Terminated",
    "Withdrawn",
    "Archived",
    "In Assurance Maintenance",
    "Other",
  ];

  const loadAllPps = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_niap_protection_profiles/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => setAllPps(response.data))
      .catch((error) => console.log(error));
  }, [product]);

  useEffect(() => {
    loadAllPps();
  }, [loadAllPps]);

  useEffect(() => {
    let pps = [];
    projpps.forEach((pp) => {
      pps.push(pp?.pp?.pp_id);
    });
    setEditedPps(pps);
  }, [product, productPps]);

  useEffect(() => {
    setProductPps(
      projpps?.map((pp) => ({ value: pp?.pp.pp_id, label: pp?.pp.pp_name }))
    );
  }, [projpps]);

  const ppOptions = allPps
    ?.filter(
      (pp) => !productPps?.some((productPp) => productPp.pp_id === pp.pp_id)
    )
    ?.map((pp) => ({ value: pp.pp_id, label: pp.pp_name }));

  const csfcSelectOptions = csfcOptions
    ?.filter(component => !editedCsfcComponents?.includes(component.value))
    ?.map(component => {
      return {value: component.name, label: component.name, url: component.link}
    });

  const stackId = isEditing ? "stackId" : null;

  const remove = (curPp) => {
    let updatedPps = [...editedPps.filter((pp) => pp !== curPp)];
    setEditedPps(updatedPps);
  };

  // filters previous vid and productId for displaying
  // previous vid number and creating vid link
  const getProductVids = product?.previous_vid?.map(v => {
    return {
      vid: v?.previous_product?.v_id,
      productId: v?.previous_product?.product_id
    }
  });

  const loadCSFCComponents = () => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}cs/proj/get_cscomponentproj_by_pid/?pid=${product.product_id}`, {
      withCredentials: true,
      headers: { Authorization: `Token ${authToken}` },
    })
    .then((response) => {
      setCsfcComponents(response.data);
      let editedResponse = response.data?.map(component => {
        return {value: component?.component, label: component?.component, url: component?.url}
      })

      setEditedCsfcComponents(editedResponse);
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    if(["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible)) {
      loadCSFCComponents();
    }
  }, [product?.csfc_eligible, product?.product_id]);

  return (
    <>
      {alertInfo.message !== "" &&
        <Row className='m-3 mb-3'>
          <Alert className="mb-0" variant={alertInfo.variant} dismissible onClose={() => setAlertInfo({message: "", variant: ""})} >
            {alertInfo.message}
          </Alert>
        </Row>
      }
      <Row>
        <Col sm={12}>
          <h3 className="fw-bold text-primary">EVALUATION PROJECT DETAILS: </h3>
        </Col>
        {!validatorFinalReview &&
          !amView &&
          permissions?.role_type !== "Vendor" && (
            <Col sm={12}>
              <Row>
                <Col md={12} lg={2}>
                  <Button
                    variant="warning"
                    className="me-3 mb-2 rounded-pill"
                    onClick={() => setShow(true)}
                  >
                    Upload Files
                  </Button>
                </Col>
                {editViewButtons()}
              </Row>
            </Col>
          )}
        <Col>
          {!isEditing && permissions.role_type === "NIAP" && (
            <div className="d-flex justify-content-end">
              <EditIcon
                color="action"
                className="clickable"
                style={{ fontSize: "2rem" }}
                onClick={() => setIsEditing(true)}
              />
            </div>
          )}
          {isEditing && (
            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                onClick={() => setIsEditing(false)}
              >
                Cancel Edit
              </Button>
              <SaveIcon
                color="action"
                className="clickable"
                style={{ fontSize: "2rem" }}
                onClick={() => handleSave()}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mt-3">
          <h3 className="fw-bold">
            {isEditing ? (
              <>
                <Form.Label>Vendor Name:</Form.Label>{" "}
                <Form.Control
                  type="text"
                  name="vendor_name"
                  onChange={handleChange}
                  defaultValue={product?.vendor_id?.name}
                />{" "}
              </>
            ) : (
              product?.vendor_id?.name
            )}
          </h3>
          <h4 className="fw-bold text-info">VID {product?.v_id}</h4>
          {product?.is_resubmission && (
            <h5>
              Previous VID{product?.previous_vid?.length > 1 ? "s" : ""}:{" "}
              {product?.is_resubmission &&
                getProductVids.map((item, index) => (
                  <span key={item.vid}>
                    <a
                      href={`/community/products/details/${item.productId}`}
                      className="vidLink"
                    >
                      {index > 0  ? `, ${item.vid}` : item.vid}
                    </a>
                  </span>
                ))}
            </h5>
          )}
        </Col>
        <Col sm={6} className="mt-3">
          <Stack className="border-start border-secondary ps-2 my-2">
            <h3 className="fw-bold">
              {isEditing ? (
                <>
                  <Form.Label>Product Name:</Form.Label>{" "}
                  <Form.Control
                    type="text"
                    name="product_name"
                    onChange={handleChange}
                    defaultValue={product?.product_name}
                  />{" "}
                </>
              ) : (
                product?.product_name
              )}
            </h3>
            <h6>
              Technology Types:{" "}
              {isEditing ? (
                <>
                  {" "}
                  <Form.Select
                    name="tech_type"
                    onChange={handleChange}
                    defaultValue={product?.tech_type?.split(',') ?? []}
                    multiple={true}
                  >
                    {techTypeOptions?.map((filteredVal, i) => (
                      <option key={i} value={filteredVal}>
                        {filteredVal}
                      </option>
                    ))}
                  </Form.Select>{" "}
                </>
              ) : (
                product?.tech_type
              )}
            </h6>
            {product?.assigned_lab?.name ? (
              <h6>
                CCTL:{" "}
                {isEditing ? (
                  <>
                    <Form.Control
                      type="text"
                      name="lab_name"
                      onChange={handleChange}
                      defaultValue={product?.assigned_lab?.name}
                    />{" "}
                  </>
                ) : (
                  product?.assigned_lab?.name
                )}{" "}
              </h6>
            ) : (
              ""
            )}
            {isEditing ? (
              <>
                <h6>
                  CSfC:
                </h6>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  name="csfc_eligible"
                  value="Yes"
                  defaultChecked={product?.csfc_eligible === "Yes"}
                  onChange={handleChange}
                  id="csfc_eligible1"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  name="csfc_eligible"
                  value="No"
                  defaultChecked={product?.csfc_eligible === "No"}
                  onChange={handleChange}
                  id="csfc_eligible2"
                />
                <Select 
                  isMulti
                  options={csfcSelectOptions}
                  onChange={(e) => handleChange({target: {name: "csfc_components", value: e}})}
                  defaultValue={() => csfcSelectOptions?.filter(comp =>
                    csfcComponents.some(component => component.component === comp.value)
                  )}
                />
              </>
            ) : (
              <>
                <h6>
                  CSfC: {["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible) ? "Yes" : "No"}
                </h6>
                {["Yes", "Maybe", "true"]?.includes(product?.csfc_eligible) &&
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px'}}>
                  CSfC Components: 
                    {csfcComponents?.map((component, index) => {
                      if (component?.url) {
                        return (
                            <a href={component.url} className="text-decoration-underlined" target="_blank" rel="noreferrer" key={index}>
                              {component.component}{index < csfcComponents.length - 1 ? ', ' : ''}
                            </a>
                        );
                      }
                      return null;
                    })}
                  </div>
                }
              </>
            )}
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack
            className="border-start border-secondary ps-2 h-100"
            id={stackId}
          >
            <h5>Protection Profiles</h5>
            {!isEditing ? (
              projpps?.map((projpp) => (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover>
                      <Popover.Body>{projpp?.pp?.pp_name}</Popover.Body>
                    </Popover>
                  }
                  key={projpp?.pp.pp_id}
                >
                  <Link
                    to={`/community/protectionprofiles/details/${projpp?.pp?.pp_id}`}
                    className="text-secondary"
                  >
                    <p className="py-0 my-0">{projpp?.pp?.pp_short_name}</p>
                  </Link>
                </OverlayTrigger>
              ))
            ) : (
              <>
                <ReactSelect
                  options={ppOptions}
                  newObj={editedPps}
                  handleChange={handleChange}
                  name="protection_profile"
                  isValid={true}
                />
                {editedPps?.map((pp) => (
                  <p key={pp}>
                    {allPps?.find((profile) => profile.pp_id === pp)?.pp_name}
                    <span
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() => remove(pp)}
                    >
                      X
                    </span>
                  </p>
                ))}
              </>
            )}
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Kickoff Date</h5>

            <h5 className="text-secondary">
              {isEditing ? (
                <Form.Control
                  type="date"
                  name="kicked_off_date"
                  onChange={handleChange}
                  defaultValue={product?.kicked_off_date ? moment.utc(product?.kicked_off_date).format('YYYY-MM-DD') : ""}
                />
              ) : product?.kicked_off_date ? (
                moment.utc(product?.kicked_off_date).format("MM/DD/YYYY")
              ) : (
                "Pending"
              )}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Days In Progress</h5>

            <h5 className="text-secondary">
              {getDaysInProgress() > 0 ? <p>{getDaysInProgress()}</p> : <p>Pending</p>}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack
            id={stackId}
            className="border-start border-secondary ps-2 h-100"
          >
            <h5>Status</h5>

            <h5 className="text-secondary">
              {isEditing ? (
                <>
                  {" "}
                  <Form.Select
                    name="status"
                    size="lg"
                    onChange={handleChange}
                    defaultValue={product?.status}
                  >
                    {statusOptions?.map((filteredVal, i) => (
                      <option key={i} value={filteredVal}>
                        {filteredVal === "Certified" ? "Completed":`${filteredVal}`}
                      </option>
                    ))}
                  </Form.Select>{" "}
                </>
              ) : product?.status === "Certified" ? (
                "Completed"
              ) : (
                product?.status
              )}
            </h5>
            <h5>Disposition:</h5>
            <h5 className="text-secondary">
              {isEditing ? (
                <>
                  <Form.Check
                    inline
                    type="radio"
                    label="Approved to list"
                    name="publish_status"
                    value="Published"
                    defaultChecked={product?.publish_status === "Published"}
                    onChange={handleChange}
                    id="publish_status1"
                  ></Form.Check>
                  <Form.Check
                    inline
                    type="radio"
                    label="Not approved to list"
                    name="publish_status"
                    value="Unpublished"
                    defaultChecked={product?.publish_status === "Unpublished"}
                    onChange={handleChange}
                    id="publish_status2"
                  ></Form.Check>
                </>
              ) : product?.publish_status === "Published" ? (
                "Approved to list"
              ) : (
                "Not approved to list"
              )}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Posted Date</h5>

            <h5 className="text-secondary">
              {isEditing ? (
                <Form.Control
                  type="date"
                  name="posted_date"
                  onChange={handleChange}
                  defaultValue={product?.posted_date ? moment.utc(product?.posted_date).format('YYYY-MM-DD') : ""}
                />
              ) : product?.posted_date ? (
                moment.utc(product?.posted_date).format("MM/DD/YYYY")
              ) : (
                "TBD"
              )}
            </h5>
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Certificate Issued</h5>

            <h5 className="text-secondary">
              {isEditing ? (
                <Form.Control
                  type="date"
                  name="certification_date"
                  onChange={handleChange}
                  defaultValue={product?.certification_date ? moment.utc(product?.certification_date).format('YYYY-MM-DD') : ""}
                />
              ) : product?.certification_date ? (
                moment.utc(product?.certification_date).format("MM/DD/YYYY")
              ) : (
                "TBD"
              )}
            </h5>
            {permissions?.role_type !== "NIAP" && product.status !== "Certified" && certificate?.draft_certificate?.file_id && (
              <Button variant="link" onClick={() => downloadFile(certificate?.draft_certificate)}>
                <FileDownload />
                Download
              </Button>
            )}
            {permissions?.role_type === "NIAP" && certificate?.certificate?.file_id && (
              <Button variant="link" onClick={() => downloadFile(certificate?.certificate)}>
                <FileDownload />
                Download
              </Button>
            )}
            {/* {certificate?.file_id && (
              <Button variant="link" onClick={updateCertificate}>
                <PriorityHigh />
                Update
              </Button>
            )} */}
          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Assurance Maintenance Date</h5>
            <h5 className="text-secondary">
              {isEditing ? (
                <Form.Control
                  type="date"
                  name="assurance_maintenance_date"
                  onChange={handleChange}
                  defaultValue={product?.assurance_maintenance_date ? moment.utc(product?.assurance_maintenance_date).format('YYYY-MM-DD') : ""}
                />
              ) : product?.assurance_maintenance_date ? (
                moment.utc(product?.assurance_maintenance_date).format("MM/DD/YYYY")
              ) : (
                "Pending"
              )}
            </h5>

          </Stack>
        </Col>
        <Col className="border-dark border-bottom border-top p-3">
          <Stack className="border-start border-secondary ps-2 h-100">
            <h5>Maintenance Releases</h5>
              {getAMColumnDetails()}
          </Stack>
        </Col>
      </Row>
    </>
  );
}
