import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector } from "react-redux";
import { handleAlertAndMailer } from "../../Helper/functions"

export default function ACMRCompletedModal({
  show,
  handleClose,
  project,
  refetch,
  setAlert,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [file, setFile] = useState({});
  const [fileInfo, setFileInfo] = useState({ file_name: "ACMR" });
  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    setFile({})
  }, [show])

  function refreshPage() {
    window.location.reload(false);
  }

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setFile({ ...file, [e.target.name]: e.target.files[0] });
    } else {
      setFileInfo({ ...fileInfo, [e.target.name]: e.target.value })
    }
  };

  const sendAlertToAmApprovers = () => {
    handleAlertAndMailer(
      csrfToken, 
      authToken, 
      {
        alert_type_id: project?.product_id,
        alert_type: "Product",
        alert_source: "Assurance Maintenance",
        subject: `ACMR has been uploaded for review for VID ${project.v_id}.`,
        recipients: { to: ["AM Approver"], cc: []},
        alert_text: `ACMR has been uploaded for review for VID ${project.v_id}.`
      }
    )
  }

  const handleUpdateProgress = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          maintenance: project?.current_assurance_maintenance?.maintenance_id,
          progress_point: 3,
          status: "In Progress",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 'Update Already Exists') {
          refreshPage();
        } else {
          handleClose();
          sendAlertToAmApprovers();
          refetch();
          setAlert({
            variant: "success",
            message: "ACMR File Successfully Uploaded!",
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const submittedFile = new FormData();
    submittedFile.append("file_type", "assurance-maintenance");
    submittedFile.append("file_type_id", project.current_assurance_maintenance?.maintenance_id);
    submittedFile.append("file", file.file);
    submittedFile.append("file_display_name", "Assurance Continuity Maintenance Report (ACMR)");
    submittedFile.append("file_label", fileInfo?.file_name);
    submittedFile.append("file_name", file?.file?.name);
    submittedFile.append("file_location", "uploads");
    submittedFile.append("file_mime_type", file?.file?.type);
    submittedFile.append("uploaded_on", new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString());
    submittedFile.append("uploaded_by", user.id);
    submittedFile.append("file_source_type", "Assurance Maintenance");
    submittedFile.append("active", JSON.stringify(true))
    submittedFile.append("private", fileInfo?.file_public_status === "Public" ? JSON.stringify(false) : JSON.stringify(true));
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
        submittedFile,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then(() => {
        handleUpdateProgress();
      })
      .catch((error) => console.log(error));
  }

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit} id="validator-checkout-approval-form">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <h3 className="text-bright-navy m-0">UPLOAD ACMR</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="mt-3">
            <Row>
              <Col sm={3}>
                <Form.Group controlId="file_name">
                  <Form.Label className="small text-secondary">
                    File Label
                  </Form.Label>
                  <Form.Control
                    value={fileInfo?.file_name}
                    type="text"
                    name="file_name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="file_public_status">
                  <Form.Label className="small text-secondary">
                    Document Visibility
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="Public">Public</option>
                    <option value="Proprietary">Proprietary</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach File
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    disabled={
                      !fileInfo.file_public_status ||
                      Object.keys(file).length > 0
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {Object.keys(file).length > 0 && (
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={5} lg={3} className="border-bottom">
                    <p className="m-0 p-2">{fileInfo?.file_name}</p>
                  </Col>
                  <Col xs={5} lg={5} className="border-bottom">
                    <p className="m-0 p-2">{fileInfo?.file_public_status}</p>
                  </Col>
                  <Col xs={2} lg={1} className="m-0 p-2 border-bottom">
                    <Button
                      variant="outline-primary"
                      className="attachment-remove"
                      onClick={() => setFile({})}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="rounded-pill"
            type="submit"
            disabled={Object.keys(file).length === 0}
          >
            Save/Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}