import { Col, Container, Form, Modal, Row, Button } from "react-bootstrap";
import { useState } from "react";

export const DocumentInfoModal = ({
  product,
  documentName,
  validationTeam,
  fetchDocument,
  show,
  handleClose,
}) => {
  //blank is default, signature line blank
  //original, signature line filled with original signed by
  //watermark, signature line blank with draft watermark
  const [optionSelect, setOptionSelect] = useState("blank");

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Process Document Generator: Data Review for{" "}
          <span className="fw-bold">
            {documentName} for VID{product?.v_id}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="productName">
                <Form.Label className="fw-bold">Product Name</Form.Label>
                <p className="px-2">{product?.product_name}</p>
              </Form.Group>
              <Form.Group controlId="options">
                <Form.Label className="fw-bold">Options:</Form.Label>
                <Form.Check
                  type="radio"
                  label="Signature Line Blank"
                  name="options"
                  value={true}
                  onChange={() => setOptionSelect("blank")}
                  id="options1"
                />
                <Form.Check
                  type="radio"
                  label="Add “Original Signed by”"
                  name="options"
                  value={true}
                  onChange={() => setOptionSelect("final")}
                  id="options2"
                />
                <Form.Check
                  type="radio"
                  label="Add Draft Watermark"
                  name="options"
                  value={false}
                  onChange={() => setOptionSelect("watermark")}
                  id="options3"
                />
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="cctlName">
                <Form.Label className="fw-bold">CCTL</Form.Label>
                <p className="px-2">{product?.assigned_lab?.name}</p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="cctlPoc">
                <Form.Label className="fw-bold">CCTL POC</Form.Label>
                <p className="px-2">{product?.assigned_lab?.poc}</p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="cctlAddress">
                <Form.Label className="fw-bold">CCTL Address</Form.Label>
                <p className="m-0 px-2">{product?.assigned_lab?.primary_address_1}</p>
                <p className="m-0 px-2">{product?.assigned_lab?.primary_address_2}</p>
                <p className="m-0 px-2">{product?.assigned_lab?.primary_state}</p>
                <p className="m-0 px-2">{product?.assigned_lab?.primary_zip}</p>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="vendorName">
                <Form.Label className="fw-bold">Vendor</Form.Label>
                <p className="px-2">{product?.vendor_id?.name}</p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="productName">
                <Form.Label className="fw-bold">Vendor POC</Form.Label>
                <p className="px-2">{product?.vendor_id?.poc}</p>
              </Form.Group>

              <Form.Group className="mb-3" controlId="productName">
                <Form.Label className="fw-bold">Validators</Form.Label>
                {validationTeam.map((validator, index) => (
                  <p className="m-0 px-2" key={index}>
                    {validator?.user_id?.first_name} {validator?.user_id?.last_name}
                  </p>
                ))}
              </Form.Group>

              <Form.Group className="mb-3" controlId="productName">
                <Form.Label className="fw-bold">Signature Block</Form.Label>
                <p className="m-0 px-2">JONATHAN ROLF</p>
                <p className="m-0 px-2">Director</p>
              </Form.Group> */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            fetchDocument(documentName, true, optionSelect);
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
