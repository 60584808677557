import { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector } from "react-redux";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import { Button, Popover, OverlayTrigger, Container, Row, Col, Alert } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import FlagIcon from "@material-ui/icons/Flag";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProposeInternationalProductModal from "../../Products/ProductsPage/Modals/ProposeInternationProductModal";
import moment from "moment";
import { Link } from "react-router-dom";
import FinalizePCMModal from "./FinalizePCMModal";
import AddPCMUpdateModal from "./AddPCMUpdateModal";
import { useInterval } from "../../../hooks/useInterval";
import qs from "qs";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PCMFilters from "./PCMFilters"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function PCMTable({setOverlayActive, setOverlayText}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPCMModal, setShowPCMModal] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const currentUser = useSelector((state) => state.user.value);
  const [pcm, setPcm] = useState({});
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const [currentFilters, setCurrentFilters] = useState({ status: 'Pending Review,Unassigned,Assigned,Review in Progress,Pending Customer Response,Ready to Be Posted' })
  const [filterOpen, setFilterOpen] = useState(true)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh, currentFilters]);

  const handleShowFinalizeModal = (rowData) => {
    if (rowData) {
      setPcm(rowData);
    }
    setShowFinalizeModal(!showFinalizeModal);
  };

  const handleShowPCMModal = (rowData) => {
    if (rowData) {
      setPcm(rowData);
    } else {
      setPcm({});
    }
    setShowPCMModal(!showPCMModal);
  };

  const handleShowPCMUpdateModal = (rowData) => {
    if (rowData) {
      setPcm(rowData);
    }
    setShowUpdateModal(!showUpdateModal);
  };

  useInterval(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, 300000); //5 min

  const loadPCMStatus = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/status/get_all_statuses`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setStatuses(
          response.data
            ?.filter((s) => s?.active === true)
            ?.map((status) => ({ value: status?.stid, label: status?.status }))
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadPCMStatus();
  }, []);

  const getPostingAgentName = (rowData) => {
    if (rowData.agent?.first_name && rowData.agent?.last_name) {
      return rowData.agent.first_name + " " + rowData.agent.last_name;
    } else {
      return "";
    }
  };

  const getReviewingAgentName = (rowData) => {
    if (rowData.qc?.first_name && rowData.qc?.last_name) {
      return rowData.qc.first_name + " " + rowData.qc.last_name;
    } else {
      return "";
    }
  };

  const customTicketSort = (a, b) => {
    const extractYearId = (str) => {
      const [, year, id] = str.match(/(\d{4})-(\d+)/) || [];
      return { year: parseInt(year), id: parseInt(id) };
    };

    const aParts = extractYearId(a.ticket);
    const bParts = extractYearId(b.ticket);

    if (aParts.year !== bParts.year) {
      return aParts.year - bParts.year;
    }

    return aParts.id - bParts.id;
  };

  const columns = [
    {
      title: "Ticket",
      field: "ticket",
      cellStyle: {
        width: "20%",
        minWidth: "20%",
        position: "sticky",
        left: 0
      },
      headerStyle: {background: "white", position: "sticky", left: 0, zIndex: 99},
      customSort: customTicketSort,
      render: (rowData) => {
        return <Link to={`details/${rowData?.rid}`}>
          PCM-{moment.utc(rowData?.entrydate).format('YYYY')}-{rowData?.ticket}
        </Link>;
      },
    },
    {
      title: "Posting Agent",
      field: "agent",
      render: (rowData) => getPostingAgentName(rowData),
    },
    {
      title: "Reviewing Agent",
      field: "qc",
      render: (rowData) => getReviewingAgentName(rowData),
    },
    {
      title: "Product",
      field: "prod",
      cellStyle: {
        width: "100%",
        minWidth: "100%",
      },
      render: (rowData) => {
        if (rowData?.maintenance_id !== null && rowData?.maintenance_id !== "") {
          return (
            <>
              <EngineeringIcon className="me-1" size="xs" />
              {rowData?.prod}
            </>
          );
        } else {
          return <>{rowData?.prod}</>;
        }
      },
    },
    { title: "Customer", field: "custuname", hiddenByColumnsButton: true },
    { title: "Scheme", field: "nsid.cid.name" },
    {
      title: "Icon",
      field: "nsid.cid.code2_iso",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <img
            src={require(`../../../images/flags2/${rowData.nsid?.cid?.code2_iso?.toLowerCase()}.png`)}
            alt={`${rowData.nsid?.cid?.code2_iso} Flag`}
          />
        );
      },
    },
    { title: "Status", field: "stid.status" },
    {
      title: "Deadline",
      field: "deadline",
      type: "date",
      render: (rowData) => {
        if (rowData?.deadline !== null) {
          return moment.utc(rowData?.deadline).format("MM/DD/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      headerStyle: {background: "white", position: "sticky", right: 0},
      cellStyle: {background: "white", position: "sticky", right: 0},
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      href={`pcm/details/${rowData?.rid}`}
                    >
                      <RemoveRedEyeIcon color="action" /> View
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowPCMModal(rowData)}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowPCMUpdateModal(rowData)}
                    >
                      <AddCircleOutlineIcon color="action" /> Add Update
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowFinalizeModal(rowData)}
                    >
                      <FlagIcon color="action" /> Finalize Request
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
              </Button>
            </OverlayTrigger>{" "}
          </>
        );
      },
    },
  ];

  const options = {
    columnsButton: true,
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    pageSize: 20,
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setFilterOpen(false);
    }
  }, []);

  const changeFilter = () => {
    setFilterOpen(!filterOpen)
  }
  return (
    <Container fluid>
      <ProposeInternationalProductModal
        showModal={showPCMModal}
        handleShowModal={handleShowPCMModal}
        setAlert={setAlert}
        currentUser={currentUser}
        refetch={() => {
          setRefresh(!refresh);
        }}
        pcm={Object.keys(pcm)?.length > 0 ? pcm : null}
        setRefetchNotes={() => { }}
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
      <FinalizePCMModal
        show={showFinalizeModal}
        handleShow={handleShowFinalizeModal}
        pcm={pcm}
        statuses={statuses}
        refetch={() => {
          setRefresh(!refresh);
        }}
        setAlert={setAlert}
      />
      <AddPCMUpdateModal
        show={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
        pcm={pcm}
        user={currentUser}
        setAlert={setAlert}
      />
      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="mt-5 mb-3">
          <Row>
            <Col sm={12} md={9} className="d-flex justify-content-end">
              <div>
                <Button
                  variant="primary"
                  className="me-3 rounded-0"
                  onClick={() => setShowPCMModal(true)}
                >
                  + Add New
                </Button>
              </div>
            </Col>
            <Col sm={12} md={3} className="d-flex justify-content-end align-content-center mb-2" style={{ minWidth: 250 }}>
              <Button variant="link rounded-pill" style={{ backgroundColor: "#dedede", maxHeight: 40 }} onClick={changeFilter}>
                {filterOpen && <>
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                  &nbsp;
                  Close Filter
                  &nbsp;
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                </>}
                {!filterOpen && <>
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                  &nbsp;
                  Open Filter
                  &nbsp;
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                </>}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={filterOpen? 9 : 12} >
              <ResponsiveMaterialTable
              title={"PRODUCT CONCURRENCE MANAGEMENT REQUEST TICKETS"}
              columns={columns}
              actions={[
                {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                    tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                        tableRef.current.onFilterChange(item.tableData.id, "");
                    }),
                }
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const { page, pageSize, search, filters, orderBy, orderDirection } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else if (["deadline"]?.includes(filter?.column?.field)) {
                      const dateObj = new Date(filter?.value);
                      const isoStr = dateObj.toISOString();
                      const formattedStr = isoStr.split("T")[0];
                      value = formattedStr;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    if(filter.column.field === "status"){
                      if(value?.length > 0){
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      } else {
                        return null
                      }
                    } else {
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    }
                  }).filter(filter => filter !== null);

                  const params = {
                    ...currentFilters,
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                    // status: "Not Rejected or Posted",
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/?${qs.stringify(params, {
                        arrayFormat: "comma",
                      })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      }
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              tableRef={tableRef}
              options={options}
            />
            </Col>
            <Col md={3} >
              {filterOpen &&
                  <PCMFilters filters={currentFilters} setFilters={setCurrentFilters} />
              }
            </Col>
          </Row>
        </Row>
      </Row>
    </Container>
  );
}
