import { Alert, Container, Row, Col, Button, ButtonGroup, ToggleButton, ToggleButtonGroup, Stack, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { faList, faBorderAll, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import Filter from './Filter';
import CCTLGridView from './UI/CCTLGridView';
import CCTLListView from './UI/CCTLListView';
import CCTLTableView from './UI/CCTLTableView';
import { useState, useEffect } from "react";
import axios from 'axios';
import './cctl.css'
import Subheader from '../Subheader';
import LetterOfIntentForm from './LetterOfIntentForm';
// import CertifyANewProductForm from '../Products/ProductsPage/CertifyANewProductForm';
import approvedCCTLImg from '../../images/cctl_page/approvedCCTL.png'
import candidateCCTLImg from '../../images/cctl_page/candidateCCTL.png'
import loiCCTLImg from '../../images/cctl_page/loiCCTL.png'
import ApprovedCCTLModal from './cctlModals/ApprovedCCTLModal';
import CandidateCCTLModal from './cctlModals/CandidateCCTLModal';
import qs from 'qs'

export default function CCTL({setOverlayActive, setOverlayText}) {
    const navigate = useNavigate();
    const [cctls, setCctls] = useState([])
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0)
    const PAGE_SIZE = 10;
    const [cctlCount, setCctlCount] = useState({})
    const [search, setSearch] = useState('');
    const [showFilters, setShowFilters] = useState(true);
    const [show, setShowModal] = useState(false);
    // const [showCertifyANewProduct, setShowCertifyANewProduct] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [removeLine, setRemoveLine] = useState(false);
    const [filters, setFilters] = useState({status: "Certified"});
    const handleShowFilter = () => setShowFilters(!showFilters);
    const [cctlView, setCctlView] = useState("List");
    const [loadCaptcha, setLoadCaptcha] = useState(false);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [showCandidateModal, setShowCandidateModal] = useState(false);
    const [alert, setAlert] = useState({message: '', type: ''});

    const cctlStatuses = [
        {name: 'status', value: 'Certified', display: 'Active'}, 
        {name: 'status', value: 'Candidate', display: 'Candidate' }, 
      ]

    const handleShowModal = () => {
        setLoadCaptcha(true)
        setShowModal(true)
    };
    // const handleShowCertifyANewProduct = () => setShowCertifyANewProduct(!showCertifyANewProduct);

    const handleCctlDisplay = (view) => {
        setCctlView(view)
        if (view === 'Table') setShowFilters(false);
    }
    const getPageBody = () => {
        if (filters?.status === "Candidate"){
            return (<p>
                Candidate Common Criteria Testing Laboratories are IT security testing laboratories that are currently 
                in the process of obtaining an accreditation by the NIST National Voluntary Laboratory Accreditation 
                Program (NVLAP).
            </p>)
        } else if (filters?.status === "Certified"){
            return (
                <p>
                    NIAP-approved Common Criteria Testing Laboratories (CCTLs) are IT security testing laboratories 
                        that are accredited by the NIST National Voluntary Laboratory Accreditation Program (NVLAP) and 
                        meet CCEVS-specific requirements to conduct IT security evaluations for conformance to the Common 
                        Criteria for Information Technology Security Evaluation, International Standard ISO/IEC 15408.

                </p>
            )
        }    
    }

    useEffect(() => {
        document.title = 'NIAP - CCTL';
      }, []);

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      }, []);
    
      useEffect(() => {
        if (windowSize.innerWidth <= 992) {
          setRemoveLine(true);
        } else {
          setRemoveLine(false);
        }
      }, [windowSize]);
    
      function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
      }

      const handleChangeFilters = (name, value) => {
        setFilters({...filters, [name]: value})
      }

    useEffect(() => {
        document.title = 'NIAP - Common Criteria Testing Laboratories';
    }, []);

  const loadCCTLCounts = () => {
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/directory/get_cctl_counts/`)
    .then(response => {
        setCctlCount(response.data)
    }).catch(error => {
      console.log(error)
    })
  }

    const loadDirectory = () => {
        let params = {
            ...filters,
            limit: PAGE_SIZE,
            offset: offset
        }
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/directory/frontend_cctls/?${qs.stringify(params, {arrayFormat: 'comma'})}`)
        .then(response => {
            setCctls(response.data?.results?.cctls);
            setTotal(response.data?.count)
        }).catch(error => {
          console.log(error)
        })
    }

    useEffect(() => {
        loadCCTLCounts()
        loadDirectory()
    }, [offset, filters])

    return (
        <>
            <ApprovedCCTLModal show={showApprovedModal} setShowApprovedModal={setShowApprovedModal} />
            <CandidateCCTLModal show={showCandidateModal} setShowCandidateModal={setShowCandidateModal} />
            <LetterOfIntentForm show={show} setShowModal={setShowModal} loadCaptcha={loadCaptcha} setAlert={setAlert} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
            {/* <CertifyANewProductForm show={showCertifyANewProduct} setShowModal={setShowCertifyANewProduct} /> */}
            <Subheader pageName={"CCTL"} />
            <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => navigate(`/`)}>
                    Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    CCTL
                </Breadcrumb.Item>
            </Breadcrumb>
            {alert?.message &&
            <Row>
                <Col>
                    <Alert variant={alert.type} onClose={() => setAlert({message: '', type: ''})} dismissible>
                        {alert.message}
                    </Alert>
                </Col>
            </Row>
            }
             <Row className='px-5'>
                <Col>
                <Row>
                    <h4 className='text-black fw-bold mb-3'>RESOURCES - <span className='text-bright-navy fw-bold text-uppercase'>Common Criteria Testing Laboratories</span></h4>
                </Row>
                        <Row>
                            {
                                getPageBody()
                            }
                        </Row>
                        <Row className='mb-5'>
                        <Col md={6} lg={2}>
                            <div className='d-flex justify-content-center icon-border'>
                                <img
                                src={approvedCCTLImg}
                                alt='approved cctl'
                                className='w-auto'
                                height='120px'
                                />
                            </div>
                            <div className='text-center'>
                                <h6 className='text-uppercase fw-bold mt-3 mb-3 text-primary'>
                                    Approved CCTL
                                </h6>
                                <Button variant="warning" onClick={() => setShowApprovedModal(true)}>Learn More</Button>
                            </div>
                        </Col>
                        {!removeLine &&
                        <Col>
                            <div className='horizontal-line'></div>
                        </Col>
                        }
                        <Col md={6} lg={2}>
                            <div className='d-flex justify-content-center icon-border'>
                                <img
                                src={candidateCCTLImg}
                                alt='candidate cctl'
                                className='w-auto'
                                height='120px'
                                />
                            </div>
                            <div className='text-center'>
                                <h6 className='text-uppercase fw-bold mt-3 mb-3 text-primary'>
                                    Candidate CCTL
                                </h6>
                                <Button variant="warning" onClick={() => setShowCandidateModal(true)}>Learn More</Button>
                            </div>
                        </Col>
                        {!removeLine &&
                        <Col>
                            <div className='horizontal-line'></div>
                        </Col>
                        }
                        <Col md={6} lg={2}>
                            <div className='d-flex justify-content-center icon-border'>
                                <img
                                src={loiCCTLImg}
                                alt='loi cctl'
                                className='w-auto'
                                height='120px'
                                />
                            </div>
                            <div className='text-center'>
                                <h6 className='text-uppercase fw-bold mt-3 text-primary'>
                                    Letter of Intent to Become a CCTL
                                </h6>
                                <Button variant="warning" onClick={handleShowModal}>Learn More</Button>
                            </div>
                        </Col>
                        </Row>
                        <Row className='px-5 pb-5 justify-content-between align-items-center'>
                            <Col className='mt-4'>
                                {
                                    filters.status === 'Certified' && 
                                        <h3 className='fw-bold text-primary text-uppercase'>
                                            Approved Labs - <span>{total}</span>
                                        </h3>
                                }
                                {
                                    filters.status === 'Candidate' && 
                                        <h3 className='fw-bold text-primary text-uppercase'>
                                            Candidate Labs - <span>{total}</span>
                                        </h3>
                                }
                                {
                                    !filters.status && 
                                    <h3 className='fw-bold text-primary text-uppercase'>
                                        All Labs - <span>{total}</span>
                                    </h3>
                                }
                            </Col>
                            <Col className='mt-4 text-end'>
                                <ButtonGroup size="md">
                                    <Button variant={filters.status === 'Certified' ? 'primary' : 'light'} className='me-4 rounded-0 shadow' onClick={() => handleChangeFilters('status', 'Certified')}>
                                        <h2 className='text-gray-400'>{cctlCount?.active}</h2>
                                        <p className='m-0'>Approved Labs</p>
                                    </Button>
                                    <Button variant={filters.status === 'Candidate' ? 'primary' : 'light'} className='me-4 rounded-0 shadow' onClick={() => handleChangeFilters('status', 'Candidate')}>
                                        <h2 className='text-gray-400'>{cctlCount?.candidate}</h2>
                                        <p className='m-0'>Candidate Labs</p>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col sm={3}>
                            <Filter 
                                show={showFilters} 
                                handleShowFilter={handleShowFilter} 
                                handleChangeFilters={handleChangeFilters} 
                                clearFilters={() => setFilters({})} 
                                filters={filters} 
                                filterOptions={[]} 
                                statusOptions={cctlStatuses} 
                            />
                        </Col>
                        <Col sm={showFilters ? 9 : 12}>
                            <Row className='justify-content-between g-3'>
                                <Col sm={12} md={3} className='d-flex justify-content-start'>
                                <Stack direction="horizontal">
                                    {!showFilters && <Button className='ms-5 my-1 border-dark' variant='light' size='sm' onClick={handleShowFilter}>Show Filter</Button>}
                                </Stack>
                                </Col>
                                <Col sm={12} md={9} className='d-flex flex-wrap justify-content-start'>
                                    <ToggleButtonGroup type="radio" name="options" defaultValue={2} className='px-4'>
                                        <ToggleButton variant='light' className='d-flex justify-content-center p-2 toggle-buttons-hover' value={1} id="tbg-btn-1" onClick={(e) => handleCctlDisplay("Table")} size='sm' active={cctlView === "Table"}>
                                        <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faList} size='lg' />
                                        <span className="visually-hidden">Table View</span>
                                        </ToggleButton>
                                        <ToggleButton variant='light' className='toggle-buttons-hover d-flex justify-content-center p-2' value={2} id="tbg-btn-2" onClick={(e) => handleCctlDisplay("List")} size='sm' active={cctlView === "List"}>
                                        <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faLayerGroup} size='lg' />
                                        <span className="visually-hidden">List View</span>
                                        </ToggleButton>
                                        <ToggleButton variant='light' className='toggle-buttons-hover d-flex justify-content-center p-2' value={3} id="tbg-btn-3" onClick={(e) => handleCctlDisplay("Grid")} size='sm' active={cctlView === "Grid"}>
                                        <FontAwesomeIcon className='align-self-center p-0 m-0' icon={faBorderAll} size='lg' />
                                        <span className="visually-hidden">Grid View</span>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                            {cctlView === 'Grid' &&
                                <CCTLGridView
                                    items={cctls}
                                    setOffset={setOffset} 
                                    offset={offset}
                                    total={total}
                                    PAGE_SIZE={PAGE_SIZE}
                                    filters={filters}
                                    search={search}
                                />
                            }
                            {cctlView === 'List' &&
                                <CCTLListView
                                    items={cctls}
                                    setOffset={setOffset} 
                                    offset={offset}
                                    total={total}
                                    PAGE_SIZE={PAGE_SIZE}
                                    filters={filters}
                                    search={search}
                                />
                            }
                            {cctlView === "Table" && <CCTLTableView sidebarFilters={filters} />}
                        </Col>
                    </Row>
                </Col>
             </Row>
            </Container>
        </>
    )
}
