import { Route, Routes } from "react-router-dom";
import NISTTable from "./NISTTable";
import NISTDetails from "./NISTDetails";
import UnauthorizedView from "../UI/UnauthorizedView"
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import axios from "axios";

const CheckPermNISTManager = ({setOverlayActive, setOverlayText}) => {
    const [cookies] = useCookies();
    let authToken = cookies["auth_token"]; 
    const [ncertMember, setNcertMember] = useState();
    const permissions = useSelector(state => state.role.value);
    const user = useSelector(state => state.user.value);

    if (permissions?.role_type === "NIAP" || permissions?.role_type === "Lab" || permissions?.role_type === "Validator") {
        return (
            <NISTManager setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
        )
    } else if (permissions?.role_type !== "NIAP" && ncertMember === false) {
        return (
            <UnauthorizedView />
        )
    }

    return (
        <div></div>
    )

}

const NISTManager = ({setOverlayActive, setOverlayText}) => {
    return (
        <Routes>
            <Route path="" element={<NISTTable />} />
            <Route path="details/:id" element={<NISTDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
        </Routes>
    )
}

export default CheckPermNISTManager