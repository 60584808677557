import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import axios from "axios";

const SiteSettingsManager = ({ refreshBanner }) => {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [maintenanceBanner, setMaintenanceBanner] = useState(false);
  const [alert, setAlert] = useState({ message: "", variant: "" });

  const handleToggle = () => {
    setMaintenanceBanner(!maintenanceBanner);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}settings/settings/get_status_maintenance_banner`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setMaintenanceBanner(response.data?.maintenance_banner);
      })
      .catch((error) => {
        setMaintenanceBanner(false);
      });
  }, []);

  const handleSave = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}settings/settings/set_maintenance_banner/`,
        {
          maintenance_banner: maintenanceBanner,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        }
      )
      .then((response) => {
        setMaintenanceBanner(response.data.maintenance_banner);
        refreshBanner();
        setAlert({ message: "Maintenance banner updated!", variant: "success" });
      })
      .catch((error) => {
        console.log("Error setting site setting", error);
        setAlert({
          message: "Error setting maintenance banner. Please try again.",
          variant: "danger",
        });
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Alert
            variant={alert.variant}
            show={alert?.message !== ""}
            dismissible
            onClose={() => setAlert({ message: "", variant: "" })}
          >
            {alert?.message}
          </Alert>
          <h1 className="mb-5 mt-3">Site Settings</h1>
          <Form.Check
            type="switch"
            id="maintenanceBannerToggle"
            label="Show Maintenance Banner?"
            checked={maintenanceBanner}
            onChange={handleToggle}
          />
          <Button variant="success" onClick={handleSave} className="mt-5">
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SiteSettingsManager;
