import { Route, Routes } from "react-router-dom";
import { useState } from 'react'
import { useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import ProductDetails from './ProductDetails'
import AssuranceMaintenanceDetails from './AssuranceMaintenanceDetails'
import ProductsTabs from "./ProductsTabs";
import SaveSignature from "./SaveSignature"
import FileTable from "../../ProjectFiles";
import NISTDetails from "../../NIST/NISTDetails";
import ProductEventLog from "./ProductEventLog";
import UnauthorizedView from "../../UI/UnauthorizedView";
import JumpVID from "./ProductUtils/JumpVID";

export default function ProductManager({ setOverlayActive, setOverlayText }) {
  const permissions = useSelector(state => state.role.value);
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState("")

  if (!permissions?.role_permissions?.find(permission => permission.area === 'Project')?.read) {
    return (
        <UnauthorizedView />
    )
  }

  return (
    <Container className="mt-2" fluid>
      <Alert show={showAlert} variant="warning" dismissible onClose={() => setShowAlert(false)}>{alertText}</Alert>
      {(permissions.role_type === "NIAP" || permissions.role_type === "Validator") && (
        <JumpVID
          setShowAlert={setShowAlert}
          setAlertText={setAlertText}
        />
      )}
      <Routes>
        {permissions?.role_type !== "Vendor" &&
          <Route path="/" element={<ProductsTabs />} />
        }
          <Route path="details/:id" element={<ProductDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:id/eventlog/:vid" element={<ProductEventLog />} />
          <Route path="details/:id/:type" element={<FileTable setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/assurancemaintenance/:id" element={<AssuranceMaintenanceDetails setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/assurancemaintenance/:id/:type" element={<FileTable setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
          <Route path="details/:product_id/nist/:id" element={<NISTDetails />} />
          <Route path="save_signature/:signer/:packageType/:id" element={<SaveSignature setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />} />
      </Routes>
    </Container>
  )
}
