import { useState, useRef, useEffect } from "react";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import axios from "axios";
import qs from "qs";
import { useCookies } from "react-cookie";
import Filter from "./Filter";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import parse from "html-react-parser";
import moment from "moment";
import "moment-timezone";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Row, Col, Button } from "react-bootstrap";

export default function ExpandedEcrAssignmentReport() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const tableRef = useRef();
  const [filterOpen, setFilterOpen] = useState(true);
  const [sidebarFilters, setSidebarFilters] = useState({
    status: "Pre-Evaluation,Finalizing,In Assurance Maintenance,In Progress",
  });
  const [labs, setLabs] = useState([]);

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [sidebarFilters]);

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, []);

  const PPTable = ({ data }) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Pt.</TableCell>
              <TableCell>Estimated</TableCell>
              <TableCell>Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.[0]?.mini_table?.map((point, index) => (
              <TableRow key={index}>
                <TableCell>{point.progress_point}</TableCell>
                <TableCell>
                  {point?.estimated_date
                    ? moment.utc(point.estimated_date).format("MM/DD/YYYY")
                    : ""}
                </TableCell>
                <TableCell>
                  {point.submission_date
                    ? moment.utc(point.submission_date).format("MM/DD/YYYY")
                    : ""}
                </TableCell>
              </TableRow>
            ))}
            {data?.[1]?.mini_table?.map((point, index) => (
              <TableRow key={index}>
                <TableCell>{point.progress_point}</TableCell>
                <TableCell>
                  {point?.estimated_date
                    ? moment.utc(point.estimated_date).format("MM/DD/YYYY")
                    : ""}
                </TableCell>
                <TableCell>
                  {point.submission_date
                    ? moment.utc(point.submission_date).format("MM/DD/YYYY")
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const columns = [
    {
      title: "VID",
      field: "v_id",
      render: rowData => <a href={`/community/products/details/${rowData.v_id}`}>{rowData.v_id}</a>,
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
      },
    },
    {
      title: "Lab",
      field: "assigned_lab",
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
      },
    },
    {
      title: "Project Team",
      field: "project_team",
      sorting: false,
      render: (rowData) => (
        <div>
          {rowData?.project_team?.map((member, index) => {
            const memberName = member?.user_id?.non_user_member
              ? member.user_id.non_user_member
              : `${member.user_id?.first_name} ${member.user_id?.last_name}`;
            return (
              <div key={index}>
                {index + 1}. {memberName} ({member?.role})
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: "Conformance",
      field: "conformance_claims",
      sorting: false,
      render: (rowData) => {
        return Array.isArray(rowData.conformance_claims)
          ? rowData.conformance_claims.join(", ")
          : "";
      },
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Progress Points 6 & 7",
      field: "role_type",
      sorting: false,
      render: (rowData) => <PPTable data={rowData.progress_points} />,
    },
    {
      title: "Days in Progress",
      field: "days_in_progress",
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 20,
    search: false,
  };

  const DetailPanel = ({ rowData }) =>
    rowData.latest_note?.note ? (
      <div>
        <div className="mb-0">{rowData.latest_note?.note && parse(rowData.latest_note?.note)}</div>
        <p className="mb-0">
          {rowData?.latest_note?.submitted_on &&
            `Submitted on ${moment.utc(rowData?.latest_note?.submitted_on).format("YYYY.MM.DD [at] HHmm")}`
          }
          {rowData?.latest_note?.submitted_by && ` by ${rowData.latest_note?.submitted_by}`}
        </p>
      </div>
    ) : (
      <div>
        <p className="mb-0">No notes available</p>
      </div>
    );

  const CustomRow = (props) => {
    const { columns, data, getFieldValue } = props;
    return (
      <>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.field}>
              {column.render ? column.render(data) : getFieldValue(data, column)}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell colSpan={columns.length}>
            <DetailPanel rowData={data} />
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Row className="mt-3 justify-content-end">
        <Col sm={12} md={2} className="d-flex justify-content-end align-content-center mb-2">
          <Button
            variant="link rounded-pill"
            style={{ backgroundColor: "#dedede", maxHeight: 40 }}
            onClick={changeFilter}
          >
            {filterOpen && (
              <>
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
                &nbsp; Close Filter &nbsp;
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
              </>
            )}
            {!filterOpen && (
              <>
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
                &nbsp; Open Filter &nbsp;
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
          <ResponsiveMaterialTable
            title="Expanded ECR Assignment Report"
            columns={columns}
            tableRef={tableRef}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const { page, pageSize, search, filters, orderBy, orderDirection } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  ...sidebarFilters,
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                };
                axios
                  .get(
                    `${
                      process.env.REACT_APP_DJANGO_ENDPOINT
                    }project/product/get_expanded_ecr_assignment_report?${qs.stringify(params, {
                      arrayFormat: "comma",
                    })}`,
                    {
                      withCredentials: true,
                      headers: {
                        Authorization: `Token ${authToken}`,
                      },
                    }
                  )
                  .then((response) => {
                    setLabs(response.data.results?.[0]?.labs);
                    resolve({
                      data: response.data.results.slice(1),
                      page: page,
                      totalCount: response.data.count-1,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            components={{
              Row: CustomRow,
            }}
            options={options}
          />
        </Col>
        {filterOpen && (
          <Col xs={10} md={3}>
            <Filter filters={sidebarFilters} setFilters={setSidebarFilters} labs={labs} />
          </Col>
        )}
      </Row>
    </>
  );
}
