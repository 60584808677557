import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import HTMLEditor from "../../../../UI/HTMLEditor";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { getISOCurrentDate } from "../../../../../utils/timeUtils";

const AddEarForm = ({ product_id, show, onHide }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [earFiles, setEarFiles] = useState({});
  const [ear, setEar] = useState({});
  const [initialCommentValid, setInitialCommentValid] = useState(true);
  const currentUser = useSelector((state) => state.user.value);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true)

  const handleChange = (e) => {
    if (e.target.name === "ear_file") {
      const newEarFiles = {
        file_type: "ear-review",
        file_source_type: "Check In",
        file_display_name: "Entropy Assessment Report (EAR)",
        uploaded_on: getISOCurrentDate(),
        file_name: e.target.files[0].name,
        file_label: ear.ear_file_label,
        active: true,
        file: e.target.files[0],
        private: true,
      };
      setEarFiles({ ...newEarFiles });
    } else if (e.target.name === "apriori" || e.target.name === "reqd") {
        setEar({ ...ear, [e.target.name]: e.target.checked });
    } else {
      setEar({ ...ear, [e.target.name]: e.target.value });
    }
  };

  const handleAddEar = async (e) => {
    e.preventDefault();
    const earData = new FormData();
    if (earFiles?.file) {
      //if ear file, we add to submitted data
      earData.append("attachments_file", earFiles.file);
      earData.append("attachments_source_type", earFiles.file_source_type);
      earData.append("attachments_uploaded", earFiles.uploaded_on);
      earData.append("active", true);
      earData.append("file_label", earFiles.file_label);
      earData.append("file_display_name", "Entropy Assessment Report (EAR)");
      earData.append("private", true);
    }
    earData.append("pid", product_id);
    earData.append("creator", currentUser?.id);
    earData.append("editor", currentUser?.id);
    earData.append("entrydate", getISOCurrentDate());
    earData.append("status", "Draft");
    earData.append("apriori", ear?.apriori ?? false);
    earData.append("reqd", ear?.reqd ?? false);
    earData.append("blurba", ear?.blurba);

    await axios
      .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/`, earData, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        onHide();
      })
      .catch((error) => console.log("Unable to submit new product: " + error));
  };

  const removeFile = () => {
    setEarFiles({});
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" enforceFocus={enforceFocusModal}>
      <Form onSubmit={handleAddEar}>
        <Modal.Header closeButton>
          <h4 className="mb-0">Add EAR Review</h4>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={6}>
                <Form.Group className="mt-1" controlId="apriori">
                  <Form.Check
                    inline
                    label={<p className="m-0">This EAR was previously approved?</p>}
                    name="apriori"
                    type="checkbox"
                    value={true}
                    onChange={handleChange}
                    id="apriori"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mt-1" controlId="reqd">
                  <Form.Check
                    inline
                    label={<p className="m-0">This EAR is required for the Evaluation?</p>}
                    name="reqd"
                    type="checkbox"
                    value={true}
                    onChange={handleChange}
                    id="reqd"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Row>
                <p className="fw-bold form-title">ATTACH EAR FILE</p>
              </Row>
              <Row>
                <Col xl={6} sm={12}>
                  <Form.Group controlId="ear_file_label">
                    <Form.Label className="small text-secondary">File Label</Form.Label>
                    <Form.Control type="text" name="ear_file_label" onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group controlId="ear_file">
                    <Form.Label className="small text-secondary">Attach EAR Files</Form.Label>
                    <Form.Control
                      type="file"
                      name="ear_file"
                      onChange={handleChange}
                      disabled={!ear.ear_file_label}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Row>
            <Container className="mt-4">
              {earFiles.file_display_name && (
                <Row className="m-3 border-bottom text-center">
                  <Col sm={5}>{earFiles.file_display_name}</Col>
                  <Col sm={5}>{earFiles.file_label}</Col>
                  {/* <Col sm={3}>{earFiles.private ? "Proprietary" : "Public"}</Col> */}
                  <Col sm={2}>
                    <Button
                      variant="outline-primary"
                      className="attachment-remove"
                      onClick={() => removeFile()}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              )}
            </Container>
            <Row className="mb-3 p-0">
              <Form.Label className="mt-3">Initial Comments:*</Form.Label>
              <HTMLEditor
                name="blurba"
                handleChange={handleChange}
                setIsValid={setInitialCommentValid}
                isValid={initialCommentValid}
                setEnforceFocusModal={setEnforceFocusModal}
              />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEarForm;
