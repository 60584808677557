import { useState, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import axios from "axios";
import ReactSelect from "../../../ReactSelect"
import reqClassData from '../../TRRTSPage/requirementClass.json';

const Filter = ({filters, setFilters}) => {
    const [cookies] = useCookies();
    const authToken = cookies["auth_token"];
    const [currentFilters, setCurrentFilters] = useState(
        {
            ...filters, 
            status: filters?.status?.split(','),
            require_pp_action: filters?.require_pp_action?.split(',')
        }
    )
    const [products, setProducts] = useState([]);
    const [trrtTeams, setTrrtTeams] = useState([])
    const [tds, setTds] = useState([]);
    //formKey is being set as current date to cause filter form to re-render on clear
    const [formKey, setFormKey] = useState("")

    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            setCurrentFilters({})
            setFormKey(Date.now());
        }
    }, [filters])

    const handleChange = (event) => {
        if (event.target.name === "status") {
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } else {
                if (event.target.checked) {
                    filter.push(event.target.value)
                } else {
                    const idx = filter.indexOf(event.target.value)
                    filter.splice(idx, 1)
                }
            }
            setCurrentFilters({...currentFilters, [event.target.name]: filter})
        } else if (event.target.name === "product_evaluation"){
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } else {
                filter = event.target.value?.join(",")
            }
            setCurrentFilters({...currentFilters, [event.target.name]: filter})
        } else if (event.target.name === "require_pp_action") {
            let filter = currentFilters[event.target.name]
            if (!filter) {
                filter = [event.target.value]
            } else {
                if (event.target.checked) {
                    filter.push(event.target.value)
                } else {
                    const idx = filter.indexOf(event.target.value)
                    filter.splice(idx, 1)
                }
            }
            setCurrentFilters({...currentFilters, [event.target.name]: filter})
        } else { 
            setCurrentFilters({...currentFilters, [event.target.name]: event.target.value})
        }
    }

    const getGroupedTds = useCallback(() => {
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_tds/`, {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          })
          .then((res) => {
            setTds(res.data?.map(td => {
                return {label: `TD${td?.td_id}`, value: td?.tq_id}
            }));
          })
          .catch((err) => console.log("unable to get technical decisions", err));
      }, []);
    
      useEffect(() => {
        getGroupedTds()
      }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setFilters({
            ...currentFilters, 
            status: currentFilters?.status?.join(','),
            require_pp_action: currentFilters?.require_pp_action?.join(',')
        })
    }

    const handleClear = (event) => {
        event.preventDefault()
        setCurrentFilters({})
        setFilters({})
        setFormKey(Date.now());
    }

    const loadProduct = ()=> { 
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products`, {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          })
          .then((response) => {
            setProducts(response.data);
          })
          .catch((error) => {
            console.log('Error getting Projects: ', error);
          });
      }
    
      useEffect(() => {
        loadProduct()
      }, []);

    const loadVirtualTeam = () =>{
        axios
          .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/get_all_virtual_teams/`, {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          })
          .then((response) => {
            setTrrtTeams(
              response.data?.filter(
                (trrt) => trrt.active === true && trrt.team_type === "TRRT Team"
              )
            );
          })
          .catch((error) => {
            console.log('Error getting TRRT Teams: ', error);
        });
      }

    useEffect(() => {
        loadVirtualTeam()
    }, [])

    const TRRTOptions = trrtTeams.map((team) => {
        return { value: team.vtid, label: team.team };
      });

      const productOptions = [
        { value: 'no_vid', label: 'No VID' },
        ...products?.map((product) => ({
          value: product.product_id,
          label: `VID${product.v_id}`,
        }))
      ];

    return (
        <Form onSubmit={handleSubmit} id="filters" key={formKey}>
            <Form.Group className="d-inline-block" controlId="from-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="from_date" onChange={handleChange} />
            </Form.Group>
            <p className="d-inline-block p-3">to</p>
            <Form.Group className="d-inline-block" controlId="to-date">
                <Form.Label>Time Frame</Form.Label>
                <Form.Control type="date" name="to_date" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="date-type" className="mb-3">
                <Form.Label>For The</Form.Label>
                <Form.Select name="date_type" onChange={handleChange}>
                    <option value="">Select a type for the time frame</option>
                    <option value="deadline">Deadline</option>
                    <option value="submitted_on">Submitted</option>
                    <option value="modified_on">Modified</option>
                    <option value="response_on">Response</option>
                    <option value="date_sent">Date Sent to iTC</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="product-name" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" name="title" onChange={handleChange} />
            </Form.Group>
            <Row>
                <Form.Label>TQ ID Range</Form.Label>
                <Col>
                    <Form.Group controlId="tq_id_start" className="mb-3">
                        <Form.Label>Start</Form.Label>
                        <Form.Control type="number" name="tq_id_start" onChange={handleChange} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="tq_id_end" className="mb-3">
                        <Form.Label>End</Form.Label>
                        <Form.Control type="number" name="tq_id_end" onChange={handleChange} />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="author" className="mb-3">
                <Form.Label>Author</Form.Label> 
                <Form.Control type="text" name="author" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="requirement" className="mb-3">
                <Form.Label>Requirement</Form.Label>
                <ReactSelect 
                    isMulti={true}
                    options={reqClassData} 
                    newObj={currentFilters} 
                    handleChange={handleChange} 
                    name={'requirement'} 
                    isValid={true}
                />
            </Form.Group>
            <Form.Group controlId="response" className="mb-3">
                <Form.Label>Response</Form.Label>
                <Form.Control type="text" name="response" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="question" className="mb-3">
                <Form.Label>Question</Form.Label>
                <Form.Control type="text" name="question" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="trrt" className="mb-3">
                <Form.Label>
                    Technical Rapid Response Team:
                </Form.Label>
                <ReactSelect isMulti={true} options={TRRTOptions} newObj={currentFilters} handleChange={handleChange} name={'team'} isValid={true} />
            </Form.Group>
            <Form.Group controlId="trrt" className="mb-3">
                <Form.Label>
                    Priority
                </Form.Label>
                <Form.Select name='urgency' onChange={handleChange}>
                    <option value="">Select...</option>
                    {['1. Delaying an Evaluation', '2. On-going Evaluation', '3. Project in Pre-Eval or General'].map((priority, i) => (
                      <option key={i} value={priority}>{priority}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="project_evaluation" className="mb-3">
                <Row className="mt-3">
                    <Form.Group
                        controlId='react-select-8-input'
                    >
                    <Form.Label>Project Evaluations</Form.Label>
                    <ReactSelect 
                        isMulti={true}
                        options={productOptions} 
                        newObj={currentFilters} 
                        handleChange={handleChange} 
                        name={'product_evaluation'} 
                        isValid={true} 
                    />
                    </Form.Group>
                </Row>
            </Form.Group>
            <Form.Group controlId="td_id" className="mb-3">
                <Row className="mt-3">
                    <Form.Group
                        className='mb-3'
                        controlId='react-select-8-input'
                    >
                    <Form.Label>Associated TDs</Form.Label>
                    <ReactSelect 
                        isMulti={false}
                        options={tds} 
                        newObj={currentFilters} 
                        handleChange={handleChange} 
                        name={'associated_tds'} 
                        isValid={true} 
                    />
                    </Form.Group>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3 p-2">
                <Form.Label>Require Action in Next PP Update</Form.Label>
                <Form.Check type="checkbox" name="require_pp_action" onChange={handleChange} label="Required" id="require_pp_action" value={true} />
                <Form.Check type="checkbox" name="require_pp_action" onChange={handleChange} label="Not Required" id="require_pp_action" value={false} />
            </Form.Group>
            <Form.Group controlId="status" className="mb-3">
                <Form.Label>Status</Form.Label>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Proposed" label="Proposed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Proposed')} id="status1"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="In Progress" label="In Progress" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'In Progress')} id="status2"/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="TD Required" label="TD Required" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'TD Required')} id="status3"/>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="TD Proposed" label="TD Proposed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'TD Proposed')} id="status4" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Resolution Proposed" label="Resolution Proposed" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Resolution Proposed')} id="status5" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Resolved" label="Resolved" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Resolved')} id="status6" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Accepted" label="Accepted" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Accepted')} id="status8" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Check type="checkbox" name="status" value="Rejected" label="Rejected" onChange={handleChange} defaultChecked={currentFilters.status?.some(status => status === 'Rejected')} id="status7" />
                    </Col>
                </Row>
            </Form.Group>
            <div className="mb-3">
                <Button type="submit" variant="primary" onClick={handleSubmit}>Apply</Button>
                &nbsp;
                <Button type="reset" variant="outline-dark" onClick={handleClear}>Clear</Button>
            </div>
        </Form>
    )
}

export default Filter