import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import {
    Button,
    OverlayTrigger,
    ListGroup,
    Tooltip
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {useState } from "react";
import moment from "moment";
import RequestAssuranceForm from "../VendorModules/RequestAssuranceForm";
import axios from "axios";
import qs from 'qs'
import {useCookies} from "react-cookie";

export default function CCTLAvailableAMTable({ tableRef, productsInAM, projpps, refetch, setAlertInfo, setOverlayActive, setOverlayText }) {
    const [cookies] = useCookies()
    let authToken = cookies['auth_token']
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [product, setProduct] = useState({});

    const handleCloseRequestModal = () => {
        refetch();
        setShowRequestModal(false);
    }

    const daysPassed = (inputDate) => {
        if (inputDate) {
          const startDate = moment(inputDate);
          const endDate = moment();
          const daysSinceStartDate = endDate.diff(startDate, "days");
          return daysSinceStartDate;
        } else {
          return 0;
        }
    };

    const columns = [
        {
            title: "VID",
            field: "v_id",
            render: (rowData) => {
                return (
                    <Link
                        to={`products/details/${rowData?.product_id}`}
                        relative="route"
                        className="text-decoration-underlined"
                    >
                        {rowData?.v_id}
                    </Link>
                );
            },
            
        },
        {
            title: "Vendor",
            field: "vendor_id.name",
        },
        {
            title: "Product",
            field: "product_name",
            render: (rowData) => {
                return (
                    <Link
                        to={`products/details/${rowData.product_id}`}
                        relative="route"
                        className="text-decoration-underlined"
                    >
                        {rowData?.product_name}
                    </Link>
                );
            },
        },
        { title: 'PP Claim', render: (rowData) => (
            <ListGroup>
            { projpps && projpps[rowData?.product_id]?.map((projpp, idx) => (
              <OverlayTrigger
              key={idx}
              placement="top"
              overlay={<Tooltip>{projpp.name}</Tooltip>}
            >
              <Link
                to={`/community/protectionprofiles/details/${projpp.id}`}
                className='text-decoration-underline'
              >
                <ListGroup.Item disabled>
                  {projpp.pp_short_name}
                </ListGroup.Item>
              </Link>
            </OverlayTrigger>
                ))
            }
          </ListGroup>
      
          ) 
        },
        {
            title: "Product Status",
            field: "status",
            render: (rowData) => (rowData.status === 'Certified' ? "Completed" : rowData.status)
        },
        {
            title: "AM Status",
            field: "current_assurance_maintenance.status",
        },
        {
            title: "Date of AM",
            field: "assurance_maintenance_date",
            render: (rowData) => {
                if(rowData.assurance_maintenance_date){
                    if(rowData.assurance_maintenance_date === rowData?.certification_date){
                        return <>No Date</>
                    } else {
                        return moment.utc(rowData.assurance_maintenance_date).format('YYYY-MM-DD')
                    }
                } else {
                    return <>No Date</>
                }
            }
        },
        {
            title: "",
            render: (rowData) => {
                if(rowData?.current_assurance_maintenance?.status === 'Draft'){
                    return (
                        <Button 
                            variant="warning" 
                            className="rounded-pill small-font" 
                            onClick={() => {
                                setProduct(rowData);
                                setShowRequestModal(true);
                            }}
                        >
                            Update Draft
                        </Button>
                    )
                } else if (rowData?.current_assurance_maintenance?.status === 'Rejected'){
                    return (
                        <Button 
                        variant="warning" 
                        className="rounded-pill small-font" 
                        onClick={() => {
                            setProduct(rowData);
                            setShowRequestModal(true);
                        }}
                        >
                        Resubmit AM
                        </Button>
                    )
                } else if (rowData?.current_assurance_maintenance?.status === "Requested"){
                    return (
                        <></>
                    )
                } else if (
                    (
                        rowData.current_assurance_maintenance !== null &&
                        rowData.current_assurance_maintenance?.lab_request === null
                    ) ||
                    rowData.certification_date !== null
                ){
                    return (
                        <Button 
                        variant="warning" 
                        className="rounded-pill small-font" 
                        onClick={() => {
                            setProduct(rowData);
                            setShowRequestModal(true);
                        }}
                        >
                        Request AM
                        </Button>
                    )
                }
            }
        },
    ];

    const options = {
        sorting: true,
        search: false,
        columnsButton: true,
        padding: "dense",
        pageSize: 20
    };

    return (
        <>
        <RequestAssuranceForm 
            show={showRequestModal} 
            handleCloseModal={handleCloseRequestModal} 
            product={product} 
            refetch={refetch} 
            setAlertInfo={setAlertInfo}
            setOverlayActive={setOverlayActive} 
            setOverlayText={setOverlayText} 
            manual={false}
        />
        <ResponsiveMaterialTable
            title={"My Products Available for AM"}
            columns={columns}
            tableRef={tableRef}
            data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                  } = query;
      
                  let newFilters = filters.map((filter) => {
                      let value = ""
                      if (Array.isArray(filter.value)) {
                          value = filter.value
                      } else {
                          value = filter.value.replace(/['"]+/g, '')
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                  }
                  axios
                      .get(
                          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/my_available_am_products/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                          {
                              withCredentials: true,
                              headers: {
                                  Authorization: `Token ${authToken}`,
                              },
                          }
                      )
                      .then((response) => {
                          resolve({
                              data: response.data.results,
                              page: page,
                              totalCount: response.data.count,
                          });
                      })
                      .catch((error) => {
                          reject(error);
                      });
                  })
                }
            options={options}
        />
        </>
    );
}
