import { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import AnnouncementModal from '../EventsAndNewsModal';
import Button from 'react-bootstrap/Button'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import qs from "qs"
import 'react-multi-carousel/lib/styles.css';
import './NewsCarousel.css'
import axios from 'axios';
import parse from 'html-react-parser';
import { useInterval } from '../../../hooks/useInterval';
import moment from 'moment';

export default function NewsCarousel({ responsiveCarousel, setOverlayActive, setOverlayText }) {

  const [news, setNews] = useState([]);
  const [currentNews, setCurrentNews] = useState({});
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState()
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Object.keys(responsiveCarousel).forEach(item => {
      const {breakpoint, items} = responsiveCarousel[item]
      const {min, max} = breakpoint

      const widths = [window.innerWidth]

      if (window.screen && window.screen.width) {
        widths.push(window.screen.width)
      }

      const screenWidth = Math.min(...widths)

      if (screenWidth >= min && screenWidth <= max) {
        setLimit(items + 1)
      }
    })
  }, [responsiveCarousel])

  const loadNews =()=>{
    const params = {
      limit: limit,
      offset: offset,
      current: true
    }
    axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_news_frontend/?${qs.stringify(params, {arrayFormat: "comma"})}`)
      .then(res => {
        setNews(res.data.results);
        setCount(res.data.count);
      }).catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (limit) {
      loadNews()
    }
  }, [offset, limit])

  useInterval(()=>{
    if (limit) {
      loadNews()
    }
  }, 300000) //5 min

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div style={{position: "absolute", width: "100%", padding: "35px"}}>
        <Button className={(currentSlide === 0) ? "visually-hidden" : ""} onClick={() => previous()} style={{float: "left", backgroundColor: "rgba(0,0,0,.5)", minWidth: "50px", height: "50px", borderRadius: "50%"}}><ArrowBackIosIcon /></Button>
        <Button className={(currentSlide === count - 4) ? "visually-hidden" : ""} onClick={() => {if (limit < count) setLimit(limit + 1); next()}} style={{float: "right", backgroundColor: "rgba(0,0,0,.5)", minWidth: "50px", height: "50px", borderRadius: "50%"}}><ArrowForwardIosIcon /></Button>
      </div>
    );
  }

  return (
    <>
    <AnnouncementModal 
      currentAnnouncement={currentNews} 
      show={showModal} 
      handleClose={() => setShowModal(false)}
      setOverlayActive={setOverlayActive} 
      setOverlayText={setOverlayText} 
    />
    <Carousel 
      responsive={responsiveCarousel}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
    >
      {news?.map(item => (
        <div className='news p-4' key={item?.id}>
          <p className='news-title p-1 bg-primary text-white'>{moment.utc(item?.posted).format('MMMM d, YYYY')}</p>
          <p className="overflow-ellipsis fw-bold">{item?.title}</p>
          <div className="d-flex justify-content-end">
            <Button 
              className="text-decoration-underline" 
              variant="link"
              onClick={() => {
                setCurrentNews(item);
                setShowModal(true);
              }}
            >
              View Details
            </Button>
          </div>
        </div>
      ))}
    </Carousel>
    </>
  )
}
