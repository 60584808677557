import { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import Teams from '../Teams';
import { Stack, Accordion, Button, Container, Row, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import FileDownload from 'js-file-download';
import moment from 'moment';

import { useNavigate, Link } from 'react-router-dom';
import { useInterval } from '../../../../hooks/useInterval';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from "@mui/icons-material/Save";

export default function SideInfo({ maintenance, product, updateSidebarFiles, refetch, setOverlayActive, setOverlayText }) {
    const [cookies] = useCookies();
    const authToken = cookies['auth_token'];
    let csrfToken = cookies["csrftoken"];
    const permissions = useSelector((state) => state.role.value);
    const [amPersonnel, setAmPersonnel] = useState([]);
    const [amFiles, setAMFiles] = useState([]);
    const [updatedDates, setUpdatedDates] = useState({});
    const [editDates, setEditDates] = useState(false);

    const navigate = useNavigate();

    const toEmailForm = (toEmail) => {
        navigate("/community/email", { state: { email: toEmail } });
    };

    const fetchMaintenancePersonnel = useCallback(()=>{
        if (maintenance?.maintenance_id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/get_all_maintenance_personnel?maintenance=${maintenance?.maintenance_id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setAmPersonnel(response.data);
                })
                .catch((error) => {
                    setAmPersonnel([]);
                });
        }
    }, [maintenance])

    useEffect(() => {
        fetchMaintenancePersonnel()
    }, [fetchMaintenancePersonnel]);

    useInterval(()=>{
        fetchMaintenancePersonnel()
    }, 180000) //3 min

    const downloadFile = (file) => {
        setOverlayText("Downloading")
        setOverlayActive(true)
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
            {
                withCredentials: true,
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                setOverlayActive(false)
                FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name))
            }).catch((error) => {
                console.log('Error')
            })
    }

    const downloadAllFiles = (source_type) => {
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/download_all_files_by_type_type_id_source_type/?file_type=assurance-maintenance&file_type_id=${maintenance?.maintenance_id}&file_source_type=Other`,
            {
                withCredentials: true,
                headers: {
                    'Authorization': `Token ${authToken}`,
                },
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, source_type + ".zip")
            }).catch((error) => {
                console.log('Error')
            })
    }

    const handleChange = (e) => {
        setUpdatedDates({...updatedDates, [e.target.name]: e.target.value})
      }

    const getAMFiles = useCallback(() => {
        if (maintenance?.maintenance_id) {
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=assurance-maintenance&file_type_id=${maintenance?.maintenance_id}`,
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${authToken}` },
                    }
                )
                .then((response) => {
                    setAMFiles(response.data?.filter(file => file?.active === true));
                })
                .catch((error) => setAMFiles([]));
        }
    }, [maintenance])

    useEffect(() => {
        getAMFiles()
    }, [getAMFiles, updateSidebarFiles])

    useInterval(()=>{
        getAMFiles()
    }, 300000) //5 min

    const maintenanceDates = () => {
        if(editDates){
            return (
            <Container>
                <Row>
                    <Form.Group controlId="team_due_date">
                    <Form.Label>
                         Validation Team Assignment:
                    </Form.Label>
                    <Form.Control
                        className="mb-2"
                        type="date"
                        name="team_due_date"
                        onChange={handleChange}
                        defaultValue={
                        maintenance?.team_due_date && 
                        moment
                        .utc(maintenance?.team_due_date)
                        .format("YYYY-MM-DD")
                        }
                    />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="acmr_due_date">
                    <Form.Label>
                        ACMR Due Date:
                    </Form.Label>
                    <Form.Control
                        className="mb-2"
                        type="date"
                        name="acmr_due_date"
                        onChange={handleChange}
                        defaultValue={
                        maintenance?.acmr_due_date && 
                        moment
                        .utc(maintenance?.acmr_due_date)
                        .format("YYYY-MM-DD")
                        }
                    />
                    </Form.Group>
                </Row>
            </Container>
            )
        } else {
        return (
            <>
                <p>
                Validation Team Assignment:{" "}
                {
                    maintenance?.team_due_date ? 
                    moment.utc(maintenance?.team_due_date).format('MM/DD/YYYY') : 
                    "No Date"
                }
                </p>
                <p>
                ACMR Due Date:{" "}
                {
                    maintenance?.acmr_due_date ? 
                    moment.utc(maintenance?.acmr_due_date).format('MM/DD/YYYY') : 
                    "No Date"
                }
                </p>
            </>
            )
        }
      }

    const handleSaveDates = async () => {
        await axios.put(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance.maintenance_id}/`, 
            {...updatedDates}, 
            {
                withCredentials: true, 
                headers: {'X-CSRFToken': csrfToken, 'Authorization':  `Token ${authToken}`}
            })
        .then(() => {
            setEditDates(false);
            refetch();
        })
        .catch(error => console.log("Unable to update product: " + error));
    }

    // this function hides the ACMR file if the maintenance status is not approved from the labs
    const hideACMRFile = () => {
        if (maintenance?.status !== 'Approved' && permissions?.role_type === 'Lab') {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Teams projectPersonnel={amPersonnel} assuranceMaintenance={true} />
            {product?.vendor_id &&
                <Accordion flush className='border border-dark mb-3' defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className='bg-secondary'><h6 className='sidebar-title'>Vendor Information</h6></Accordion.Header>
                        <Accordion.Body className='bg-light p-1'>
                            <p className='m-0'>{product?.vendor_id?.name}</p>
                            <p className='m-0'>POC: {maintenance?.vendor_poc ?? product.vendor_poc}</p>
                            <p className='m-0'>{product?.vendor_id?.primary_address_1}</p>
                            <p className='m-0'>{product?.vendor_id?.primary_address_2}</p>
                            <p className='m-0'>{product?.vendor_id?.primary_state}</p>
                            <p className='m-0'>{product?.vendor_id?.primary_zip}</p>
                            {maintenance.vendor_email && <Button
                                variant="link"
                                className="p-0 text-bright-blue"
                                style={{
                                    wordBreak: "break-word",
                                }}
                                onClick={() => toEmailForm(maintenance?.vendor_email)}
                            >
                                {maintenance?.vendor_email}
                            </Button>
                            }
                            {!maintenance.vendor_email && product.vendor_email && <Button
                                variant="link"
                                className="p-0 text-bright-blue"
                                style={{
                                    wordBreak: "break-word",
                                }}
                                onClick={() => toEmailForm(product?.vendor_email)}
                            >
                                {product?.vendor_email}
                            </Button>
                            }
                            <p className='m-0'>{maintenance?.vendor_phone ?? product.vendor_phone}</p>
                            <p className='m-0 text-primary'>{product?.vendor_id?.website}</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
            <Accordion flush className='border border-dark mb-3' defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header className='bg-secondary'><h6 className='sidebar-title'>Due Dates</h6></Accordion.Header>
                    <Accordion.Body className='bg-light p-1'>
                        {!editDates && 
                            permissions?.role_type === 'NIAP' &&
                            <div className='d-flex justify-content-end'>
                                <EditIcon color="action" className="clickable" onClick={() => setEditDates(true)}/>
                            </div>
                        }
                        {editDates &&
                            <div className='d-flex justify-content-end'>
                                <SaveIcon color="action" className="clickable" onClick={handleSaveDates}/>
                            </div>
                        }
                        {maintenanceDates()}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {product?.assigned_lab &&
                <Accordion flush className='border border-dark mb-3' defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className='bg-secondary'><h6 className='sidebar-title'>CCTL Information</h6></Accordion.Header>
                        <Accordion.Body className='bg-light p-1'>
                            <p className='m-0'>{product?.assigned_lab?.name}</p>
                            <p className='m-0'>POC: {product?.assigned_lab?.poc}</p>
                            <p className='m-0'>{product?.assigned_lab?.primary_address_1}</p>
                            <p className='m-0'>{product?.assigned_lab?.primary_address_2}</p>
                            <p className='m-0'>{product?.assigned_lab?.primary_state}</p>
                            <p className='m-0'>{product?.assigned_lab?.primary_zip}</p>
                            {product.assigned_lab.poc_email && <Button
                                variant="link"
                                className="p-0 text-bright-blue"
                                style={{
                                    wordBreak: "break-word",
                                }}
                                onClick={() => toEmailForm(product.assigned_lab.poc_email)}
                            >
                                {product.assigned_lab.poc_email}
                            </Button>
                            }
                            <p className='m-0'>{product?.assigned_lab?.primary_phone}</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
            <Accordion flush className='border border-dark mb-3' defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header className='bg-secondary'><h6 className='sidebar-title'>Assurance Maintenance Files</h6></Accordion.Header>
                    <Accordion.Body style={{ background: 'var(--bs-gray-300)' }}>
                        {amFiles?.filter(file => !hideACMRFile())?.map((file, idx) => (
                            <Stack  direction='horizontal' className='d-flex justify-content-left align-items-start' key={idx}>
                                {file.file_mime_type === 'application/pdf' && <FontAwesomeIcon icon={faFilePdf} color='red' size='xl' />}
                                {file.file_mime_type !== 'application/pdf' && <FontAwesomeIcon icon={faFileLines} color='gray' size='xl' />}
                                <div className="ms-2 text-break">
                                    <Button style={{overflowWrap: "break-word"}} className="text-start m-0 p-0" variant="link" onClick={() => downloadFile(file)}>
                                        {file.file_label ? file.file_label : file.file_new_name ? file.file_new_name : file.file_name}
                                    </Button>
                                    <p className="mb-0 ms-3">
                                        {file.file_new_name ? file.file_new_name : file.file_name}
                                    </p>
                                    <p className="mb-0 ms-3">
                                        Attached by {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name} on {file?.uploaded_on && moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
                                    </p>
                                </div>
                            </Stack>
                        ))}
                        <Link to={`/community/products/details/${product?.product_id}/assurancemaintenance/${maintenance?.maintenance_id}/am_files`}>
                            <Button variant="primary">View All</Button>
                        </Link>
                        <Button variant='primary' onClick={() => downloadAllFiles(maintenance?.maintenance_id, "Check In", authToken)}>Download All</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}