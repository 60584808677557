import MaterialTable from "material-table";
import TablePagination from "@mui/material/TablePagination";
import Input from "@mui/material/Input";
import {
    Container,
    Button,
    OverlayTrigger,
    Tooltip,
    ListGroup,
    Stack,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import qs from 'qs'
import axios from "axios";
import moment from 'moment';
import { latestApprovedMaintenance } from "../../Helper/functions";

export default function ProductTableView({
    maintenances,
    sidebarFilters,
    projpps,
    ccPps,
    tablePageSize
}) {
    const tableRef = useRef()

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [sidebarFilters, tablePageSize])

    const getCardName = (card) => {
        if(card?.certification_status === "Certified" && card?.status !== "Archived" && !card.is_archived){
            return "Certified"
        } else if(card?.is_archived || card?.status === "Archived"){
            return "Archived"
        } else {
            return card?.status
        }
    }

    const columns = [
        { title: "VID", field: "v_id", sorting: false },
        { title: "Vendor", field: "vendor_id_name" },
        { title: "Product", field: "product_name" },
        { title: "CCTL", field: "assigned_lab_name" ,
          render: (rowData)  => {
            return <span>{rowData.assigned_lab_name}</span>
          }
        },
        { 
            title: "Certification Date", 
            field: "certification_date", 
            hidden: sidebarFilters.status === "In Progress",
            render : (rowData) => {
                return (
                    rowData?.certification_date &&
                    moment(
                        rowData?.certification_date
                    ).utc().format("MM/DD/YYYY")
                );
            }
        },
        { title: "Kickoff Date", field: "kicked_off_date", render: (rowData) => rowData.kicked_off_date && moment.utc(rowData.kicked_off_date).format('MM/DD/YYYY'), hidden: sidebarFilters.status !== "In Progress"},
        {
            title: "Status",
            field: "status_sort",
            render: (rowData) => (
                <>
                    <FontAwesomeIcon
                        icon={faCircle}
                        className="me-1"
                        color={
                            rowData?.status === "Archived" || rowData?.is_archived
                                ? "var(--bs-orange)"
                                : rowData?.certification_status === "Certified"
                                ? "var(--bs-green)"
                                : "var(--bs-yellow)"
                        }
                        size="xs"
                    />
                    {getCardName(rowData)}
                </>
            ),
        },
        // { 
        //   title: "Archived Date", 
        //   field: "sunset_date", 
        //   type: 'date', 
        //   hidden: sidebarFilters.status !== "Archived",
        //   render: (rowData) => {
        //     if (!rowData.sunset_date || rowData.status!== "Archived" ){
        //       return "Pending"
        //     }
        //     return moment.utc(rowData.sunset_date).format('MM/DD/YYYY')
        // }},
        {
            title: "Conformance Claims",
            field: "project_pp_product.pp.pp_short_name",
            sorting: false,
            render: (rowData) => (
                rowData?.from_cc_portal ? 
                <ListGroup>
                    {ccPps &&
                        ccPps[rowData?.product_id]?.map((projpp, idx) => (
                            <OverlayTrigger
                                key={idx}
                                placement="top"
                                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                            >
                                <Link
                                    to={`/protectionprofiles/${projpp.pp_id}`}
                                    className="text-decoration-underline"
                                >
                                    <ListGroup.Item disabled style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px"}}>
                                        {projpp?.pp_short_name ? projpp?.pp_short_name : projpp?.pp_name}
                                    </ListGroup.Item>
                                </Link>
                            </OverlayTrigger>
                        ))}
                </ListGroup> :
                <ListGroup>
                    {projpps &&
                        projpps[rowData?.product_id]?.map((projpp, idx) => (
                            <OverlayTrigger
                                key={idx}
                                placement="top"
                                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                            >
                                <Link
                                    to={`/protectionprofiles/${projpp.id}`}
                                    className="text-decoration-underline"
                                >
                                    <ListGroup.Item disabled style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px"}}>
                                        {projpp?.pp_short_name ? projpp?.pp_short_name : projpp?.pp_name}
                                    </ListGroup.Item>
                                </Link>
                            </OverlayTrigger>
                        ))}
                </ListGroup>
            ),
        },
        {
            title: "Assurance Maintenance Date",
            field: "am_sort",
            type: "date",
            hidden: sidebarFilters.status === "Archived" || sidebarFilters.status === "In Progress",
            render: (rowData) => {
                return (
                    rowData.status !== "Archived" && !rowData.is_archived && ((rowData?.am_sort) ?
                    moment.utc(rowData?.am_sort).format("MM/DD/YYYY") :
                    '')
                );
            },
        },
        {
            title: "Maintenance Update",
            sorting: false,
            field: "project_maintenance_product.title",
            hidden: sidebarFilters.status === "Archived" || sidebarFilters.status === "In Progress",
            render: (rowData) => {
                let latestMaintenance = latestApprovedMaintenance(maintenances[rowData?.product_id])
                if(latestMaintenance && !("from_cc_portal" in rowData)){
                    return (
                        (latestMaintenance &&
                            <>
                                <strong>
                                    {moment.utc(latestMaintenance?.completion_date).format('YYYY.MM.DD')}
                                </strong>
                                &nbsp;
                                <Link
                                    to={`/products/${rowData.product_id}/assurancemaintenance/${latestMaintenance?.maintenance_id}`}
                                >
                                    {latestMaintenance?.title}
                                </Link>
                            </>
                        ));
                } else if ("from_cc_portal" in rowData && maintenances[rowData?.product_id]){
                    return (
                        <Stack>
                            {maintenances[rowData?.product_id]?.map((maintenance, idx) => (
                                <>
                                    <strong>{moment.utc(maintenance?.completion_date).format('YYYY.MM.DD')}</strong>
                                    &nbsp;
                                    <Link
                                        key={idx}
                                        to={`/products/international-product/${rowData?.product_id}/assurancemaintenance/${maintenance?.maintenance_id}`}
                                        className="text-decoration-underline"
                                    >
                                        {maintenance?.title}
                                    </Link>
                                </>
                            ))}
                        </Stack>
                    )
                } else {
                    return null
                }
            },
        },
        {
            title: "Scheme",
            field: "submitting_country_id_code",
            render: (rowData) =>
                rowData?.submitting_country_id_code && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip-2">
                                {rowData?.submitting_country_id_name}
                            </Tooltip>
                        }
                    >
                        {({ ref, ...triggerHandler }) => (
                            <img
                                ref={ref}
                                {...triggerHandler}
                                src={require(`../../../../images/flags2/${rowData?.submitting_country_id_code?.toLowerCase()}.png`)}
                                className="me-5"
                                height="50px"
                                width="50px"
                                alt="Country Flag"
                            />
                        )}
                    </OverlayTrigger>
                ),
        },
        {
            render: (rowData) => {
                return (
                    rowData?.status !== "Archived" && !rowData?.is_archived && rowData?.certification_status === 'Certified' && (
                        <Link to={
                            !("from_cc_portal" in rowData) ? 
                            `/products/${rowData?.product_id}` :
                            `/products/international-product/${rowData?.product_id}`
                        }>
                            <Button
                                variant="light"
                                size="sm"
                                className="border-dark btn-hover"
                            >
                                Full Details
                            </Button>
                        </Link>
                    )
                );
            },
        },
    ];

    const options = {
        headerStyle: {
            fontWeight: "bold",
            paddingBottom: "0",
        },
        pageSizeOptions: [5, 10, 20, 50, 100],
        pageSize: tablePageSize ?? 100
    };

    return (
        <Container className="mt-4" fluid>
            <MaterialTable
                title="Showing"
                tableRef={tableRef}
                columns={columns}
                data={(query) => 
                    new Promise((resolve, reject) => {
                        // Extract the necessary information from the query object
                        const {
                            page,
                            pageSize,
                            search,
                            filters,
                            orderBy,
                            orderDirection,
                        } = query;
        
                        let newFilters = filters.map((filter) => {
                            let value = ""
                            if (Array.isArray(filter.value)) {
                                value = filter.value
                            } else {
                                value = filter.value.replace(/['"]+/g, '')
                            }
                            return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                        });

                        let conditionalPageSize = pageSize

                        if(tablePageSize){
                            conditionalPageSize = tablePageSize
                        }

                        const params = {
                            ...sidebarFilters,
                            offset: page * conditionalPageSize,
                            limit: conditionalPageSize,
                            search: search,
                            filters: newFilters,
                            orderBy: orderBy?.field,
                            orderDirection: orderDirection,
                            publish_status: "Published",
                        }

                        axios
                            .get(
                                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/pcl_products/?${qs.stringify(params, {arrayFormat: 'comma'})}`
                            )
                            .then((response) => {
                                const displayProducts =
                                  response.data.results.products.map(
                                    (product) => {
                                      if (product.status === "Finalizing") {
                                        product.status = "In Progress";
                                      }
                                      return product;
                                    }
                                  );

                                resolve({
                                  data: displayProducts,
                                  page: page,
                                  totalCount: response.data.count,
                                });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                }
                options={options}
                components={{
                    Pagination: (props) => (
                        <TablePagination
                            {...props}
                            labelRowsPerPage={
                                <label htmlFor="rowsPerPage">
                                    Rows Per Page
                                </label>
                            }
                            SelectProps={{ input: <Input id="rowsPerPage" /> }}
                        />
                    ),
                }}
            />
        </Container>
    );
}
