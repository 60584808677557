import { useState, useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import ApprovedProductsLogo from "../../../images/products/ApprovedProducts.png";
import intPartnersImg from '../../../images/niap_ccevs/int_partners.png';
import InEvaluationProductsLogo from "../../../images/products/ProductsInEvaluation.png";
import ArchivedProductsLogo from "../../../images/products/ArchivedProducts.png";
import ApprovedProductsModal from './Modals/ApprovedProductsModal';
import ProductsInEvalModal from './Modals/ProductsInEvalModal';
import ArchivedProductsModal from './Modals/ArchivedProductsModal';
import CertifyANewProductLogo from "../../../images/products/CertifyANewProduct.png";
import ProposeInternationalProductModal from './Modals/ProposeInternationProductModal';
import {Link} from 'react-router-dom'

export default function ProductModals({setOverlayActive, setOverlayText}) {
    const [showApprovedProductsModal, setShowApprovedProductsModal] = useState(false);
    const [showProductsInEvalModal, setShowProductsInEvalModal] = useState(false);
    const [showArchivedProductsModal, setShowArchivedProductsModal] = useState(false);
    const [showProposeIntProductsModal, setShowProposeIntProductsModal] = useState(false);
    const [showLine, setShowLine] = useState(true);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [alert, setAlert] = useState({message: "", type: ""})

    const handleShowApprovedProductsModal = () => setShowApprovedProductsModal(!showApprovedProductsModal);
    const handleShowProductsInEvalModal = () => setShowProductsInEvalModal(!showProductsInEvalModal);
    const handleShowArchivedProductsModal = () => setShowArchivedProductsModal(!showArchivedProductsModal);
    const handleShowProposeIntProductsModal = () => setShowProposeIntProductsModal(!showProposeIntProductsModal)

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        if (windowSize.innerWidth <= 992) {
            setShowLine(false);
        } else {
            setShowLine(true);
        }
    }, [windowSize]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <Row className="py-3 d-flex justify-content-center">
            {alert.message !== "" && (
                <Row className="d-flex justify-content-center">
                    <Col sm={8}>
                        <Alert
                            variant={alert.type}
                            dismissible
                            onClose={() => setAlert({message: "", type: ""})}
                        >
                            {alert.message}
                        </Alert>
                    </Col>
                </Row>
            )}
            <Col xs={12} sm={6} lg={2} className="text-center border-0">
                <img src={CertifyANewProductLogo} className='border-bottom border-warning border-5' height='150px' alt='Certify a New Product Logo' />
                <h5 className="text-uppercase fw-bold text-primary">Certify a New Product</h5>
                <Link to="/cctl">
                    <Button variant="warning">CCTLs</Button>
                </Link>
                <p>See the List of approved CCTLs</p>
            </Col>
            {showLine &&
                <Col><div className="horizontal-line"></div></Col>
            }
            <Col xs={12} sm={6} lg={2} className="text-center border-0">
                <img src={ApprovedProductsLogo} className='border-bottom border-warning border-5' height='150px' alt='Compliant Products Logo' />
                <h5 className="text-uppercase fw-bold text-primary">Compliant Products</h5>
                <Button variant='warning' onClick={handleShowApprovedProductsModal}>Learn More</Button>
                <ApprovedProductsModal showModal={showApprovedProductsModal} handleShowModal={handleShowApprovedProductsModal} />
            </Col>
            {showLine &&
                <Col><div className="horizontal-line"></div></Col>
            }
            <Col xs={12} sm={6} lg={2} className="text-center border-0">
                <img src={InEvaluationProductsLogo} className='border-bottom border-warning border-5' height='150px' alt='In Evaluation Products Logo' />
                <h5 className="text-uppercase fw-bold text-primary">Products In Evaluation</h5>
                <Button variant='warning' onClick={handleShowProductsInEvalModal}>Learn More</Button>
                <ProductsInEvalModal showModal={showProductsInEvalModal} handleShowModal={handleShowProductsInEvalModal} />
            </Col>
            {showLine &&
                <Col><div className="horizontal-line"></div></Col>
            }
            <Col xs={12} sm={6} lg={2} className="text-center border-0">
                <img src={intPartnersImg} className='border-bottom border-warning border-5' height='150px' alt='internation posting Logo' />
                <h5 className="text-uppercase fw-bold text-primary">International Posting</h5>
                <Button variant='warning' onClick={handleShowProposeIntProductsModal}>Propose</Button>
                <ProposeInternationalProductModal showModal={showProposeIntProductsModal} handleShowModal={handleShowProposeIntProductsModal} setAlert={setAlert} setOverlayActive={setOverlayActive} setOverlayText={setOverlayText} />
            </Col>
            {showLine &&
                <Col><div className="horizontal-line"></div></Col>
            }
            <Col xs={12} sm={6} lg={2} className="text-center border-0">
                <img src={ArchivedProductsLogo} className='border-bottom border-warning border-5' height='150px' alt='Archived Products Logo' />
                <h5 className="text-uppercase fw-bold text-primary">Archived<br></br> Products</h5>
                <Button variant='warning' onClick={handleShowArchivedProductsModal}>Learn More</Button>
                <ArchivedProductsModal showModal={showArchivedProductsModal} handleShowModal={handleShowArchivedProductsModal} />
            </Col>
        </Row>
    );
}
