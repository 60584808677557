import { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemSecondaryAction from '@mui/material/ListItem';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import RateReviewIcon from "@mui/icons-material/RateReview";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import ShieldIcon from "@mui/icons-material/Shield";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import GroupIcon from "@mui/icons-material/Group";
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BeenhereIcon from "@mui/icons-material/Beenhere"
import ContactMailIcon from '@mui/icons-material/ContactMail'
import StorageIcon from "@mui/icons-material/Storage"
import CasesIcon from "@mui/icons-material/CasesSharp"
import AssignmentIcon from '@mui/icons-material/Assignment';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward } from '@fortawesome/free-solid-svg-icons'
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
// import ShowChartIcon from "@mui/icons-material/ShowChart";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import HandshakeIcon from "@mui/icons-material/Handshake";
import LinkIcon from "@mui/icons-material/Link";
// import HelpIcon from "@mui/icons-material/Help";
// import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import axios from "axios";

export default function Drawer({setSelectedDashBoard, sidebarOpen, setSidebarOpen}) {
  // const [open, setSidebarOpen] = useState(false);
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"]; 
  const location = useLocation();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const role = useSelector(state => state.role.value);
  const user = useSelector(state => state.user.value);
  const [openChild, setOpenChild] = useState(false)
  const [openQuickLinks, setOpenQuickLinks] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(1350)
  const [ertMember, setERTMember] = useState(false);
  const [ncertMember, setNcertMember] = useState(false);
  const [trrtMember, setTRRTMember] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if(openQuickLinks){
      setDrawerHeight(drawerHeight + 200);
    } else {
      setDrawerHeight(1350)
    }
  }, [openQuickLinks])


  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const toggleOpenDrawer = () => {
    setOpenChild(false)
    setSidebarOpen(!sidebarOpen);
  };

  const drawerWidth = 220;

  const getIsErtMember = useCallback(() => {
    if (user) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${user.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setERTMember(response.data.is_member);
        })
        .catch((err) => console.log("unable to get member's virtual teams", err));
    }
  }, [user]);

  const getIsNcertMember = useCallback(() => {
    if (user) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ncrt_team_member?user=${user.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          }
        )
        .then((response) => {
          setNcertMember(response.data.is_member);
        })
        .catch((err) => console.log("unable to get member's virtual teams", err));
    }
  }, [user]);

  const getIsTRRTMember = useCallback(() => {
    if(user){
      axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_trrt_team_member/?user=${user.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        }
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setTRRTMember(true)
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));
    }
  }, [user]);

  useEffect(() => {
    getIsErtMember();
  }, [getIsErtMember]);

  useEffect(() => {
    getIsNcertMember();
  }, [getIsNcertMember]);

  useEffect(() => {
    getIsTRRTMember()
  }, [getIsTRRTMember])

  const cctlDashboardOptions = [
    {
      icon: <CategoryIcon />,
      name: "PRODUCTS",
      link: "/community/products",
      show: role?.role_permissions?.find(permission => permission.area === 'Project')?.read
    },
    {
      icon: <ShieldIcon />,
      name: "PROTECTION PROFILES",
      link: "/community/protectionprofiles",
      show: role?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.read
    },
    {
      icon: <BusinessIcon />,
      name: "ORGANIZATIONS MANAGER",
      link: "/community/organizations",
      show: role?.role_permissions?.find(permission => permission.area === 'Organization')?.read
    },
    {
      icon: <GroupIcon />,
      name: "VIRTUAL TEAMS",
      link: "/community/virtualteams",
      show: role?.role_permissions?.find(permission => permission.area === 'Virtual Teams')?.read
    },
    {
      icon: <AltRouteIcon />,
      name: "TRRTS",
      link: "/community/technicalqueries",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Questions')?.read
    },
    {
      icon: <FontAwesomeIcon icon={faAward} size="xl" style={{padding: 3}}/>,
      name: "CERT REVIEWS",
      link: "/community/nist",
      show: true,
    },
    {
      icon: <BeenhereIcon />,
      name: "TECHNICAL DECISIONS",
      link: "/community/technical-decisions",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Decisions')?.read
    },
    {
      icon: <WorkspacePremiumIcon />,
      name: "EAR REVIEWS",
      link: "/community/ear-reviews",
      show: role?.role_permissions?.find(permission => permission.area === 'EAR Reviews')?.read && role.role_type !== "Lab"
    },
    {
      icon: <RateReviewIcon />,
      name: "ECR COMMENTS",
      link: "/community/ecr-comments",
      show: role?.role_permissions?.find(permission => permission.area === 'ECR Comments')?.read
    },
    {
      icon: <StorageIcon />,
      name: "DOCUMENT MANAGER",
      link: "/community/documents",
      show: role?.role_permissions?.find(permission => permission.area === 'Document Manager')?.read
    },
    // {
    //   icon: <ShowChartIcon />,
    //   name: "REPORTS",
    //   link: "/",
    // },
    // {
    //   icon: <LibraryBooksIcon />,
    //   name: "DOCUMENT LIBRARY",
    //   link: "/",
    // },
    // {
    //   icon: <NotificationsIcon />,
    //   name: "NOTIFICATIONS",
    //   link: "/",
    // },
    // {
    //   icon: <HandshakeIcon />,
    //   name: "RESOURCES",
    //   link: "/",
    // }
  ];

  const niapDashboardOptions = [
    {
      icon: <FontAwesomeIcon icon={faCalendar} size="xl" style={{padding: 3}}/>,
      name: "EVALUATION CALENDAR",
      link: "/community/calendar",
      show: true,
    },
    {
      icon: <CategoryIcon />,
      name: "PRODUCTS",
      link: "/community/products",
      show: role?.role_permissions?.find(permission => permission.area === 'Project')?.read
    },
    {
      icon: <ShieldIcon />,
      name: "PROTECTION PROFILES",
      link: "/community/protectionprofiles",
      show: role?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.read
    },
    {
      icon: <QuestionAnswerIcon />,
      name: "Q&A",
      link: "/community/question-answer",
      show: true
    },
    {
      icon: <GroupIcon />,
      name: "VIRTUAL TEAMS",
      link: "/community/virtualteams",
      show: role?.role_permissions?.find(permission => permission.area === 'Virtual Teams')?.read
    },
    {
      icon: <BusinessIcon />,
      name: "ORGANIZATIONS MANAGER",
      link: "/community/organizations",
      show: role?.role_permissions?.find(permission => permission.area === 'Organization')?.read
    },
    // {
    //   icon: <SettingsApplicationsIcon />,
    //   name: "TECHNICAL DECISIONS",
    //   link: "/community/#",
    // },
    {
      icon: <AltRouteIcon />,
      name: "TRRTS",
      link: "/community/technicalqueries",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Questions')?.read
    },
    {
      icon: <CasesIcon />,
      name: "INTERNATIONAL POSTING REQUESTS",
      link: "/community/pcm",
      show: role?.role_type === 'NIAP'
    },
    {
      icon: <FontAwesomeIcon icon={faAward} size="xl" style={{padding: 3}}/>,
      name: "CERT REVIEWS",
      link: "/community/nist",
      show: true,
    },
    {
      icon: <BeenhereIcon />,
      name: "TECHNICAL DECISIONS",
      link: "/community/technical-decisions",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Decisions')?.read
    },
    // {
    //   icon: <WorkspacePremiumIcon />,
    //   name: "CERT REVIEWS",
    //   link: "/community/#",
    // },
    {
      icon: <WorkspacePremiumIcon />,
      name: "EAR REVIEWS",
      link: "/community/ear-reviews",
      show: true
    },
    {
      icon: <RateReviewIcon />, 
      name: "ECR COMMENTS",
      link: "/community/ecr-comments",
      show: role?.role_permissions?.find(permission => permission.area === 'ECR Comments')?.read
    },
    {
      icon: <AssignmentIcon />,
      name: "REPORTS",
      link: "/community/reports",
      show: role?.role_permissions?.find(permission => permission.area === 'Project')?.read
    },
    {
      icon: <StorageIcon />,
      name: "DOCUMENT MANAGER",
      link: "/community/documents",
      show: role?.role_permissions?.find(permission => permission.area === 'Document Manager')?.read
    },
    {
      icon: <LinkIcon />,
      name: "NIAP QUICK LINKS",
      show: role?.role_type === 'NIAP'
    },
    // {
    //   icon: <NotificationsIcon />,
    //   name: "NOTIFICATIONS",
    //   link: "/community/#",
    // },
    // {
    //   icon: <ShowChartIcon />,
    //   name: "REPORTS",
    //   link: "/community/#",
    // },
    // {
    //   icon: <HandshakeIcon />,
    //   name: "RESOURCES",
    //   link: "/community/#",
    // },
    // {
    //   icon: <LibraryBooksIcon />,
    //   name: "DOCUMENT LIBRARY",
    //   link: "/community/#",
    // },
    // {
    //   icon: <LinkIcon />,
    //   name: "NIAP QUICK LINKS",
    //   link: "/community/#",
    // },
  ];

  const validatorDashboardOptions = [
    {
      icon: <CategoryIcon />,
      name: "PRODUCTS",
      link: "/community/products",
      show: role?.role_permissions?.find(permission => permission.area === 'Project')?.read
    },
    {
      icon: <ShieldIcon />,
      name: "PROTECTION PROFILES",
      link: "/community/protectionprofiles",
      show: role?.role_permissions?.find(permission => permission.area === 'Protection Profile')?.read
    },
    {
      icon: <GroupIcon />,
      name: "VIRTUAL TEAMS",
      link: "/community/virtualteams",
      show: role?.role_permissions?.find(permission => permission.area === 'Virtual Teams')?.read
    },
    {
      icon: <BusinessIcon />,
      name: "ORGANIZATIONS MANAGER",
      link: "/community/organizations",
      show: role?.role_permissions?.find(permission => permission.area === 'Organization')?.read
    },
    {
      icon: <AltRouteIcon />,
      name: "TRRTS",
      link: "/community/technicalqueries",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Questions')?.read
    },
    {
      icon: <BeenhereIcon />,
      name: "TECHNICAL DECISIONS",
      link: "/community/technical-decisions",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Decisions')?.read
    },
    {
      icon: <WorkspacePremiumIcon />,
      name: "EAR REVIEWS",
      link: "/community/ear-reviews",
      show: role?.role_permissions?.find(permission => permission.area === 'EAR Reviews')?.read
    },
    {
      icon: <RateReviewIcon />, 
      name: "ECR COMMENTS",
      link: "/community/ecr-comments",
      show: role?.role_permissions?.find(permission => permission.area === 'ECR Comments')?.read
    },
    {
      icon: <FontAwesomeIcon icon={faAward} size="xl" style={{padding: 3}}/>,
      name: "CERT REVIEWS",
      link: "/community/nist",
      show: true,
    },
    {
      icon: <AssignmentIcon />,
      name: "REPORTS",
      link: "/community/reports",
      show: role?.role_permissions?.find(permission => permission.area === 'Project')?.read
    },
    {
      icon: <StorageIcon />,
      name: "DOCUMENT MANAGER",
      link: "/community/documents",
      show: role?.role_permissions?.find(permission => permission.area === 'Document Manager')?.read
    },
  ];

  const smeDashboardOptions = [
    {
      icon: <WorkspacePremiumIcon />,
      name: "EAR REVIEWS",
      link: "/community/ear-reviews",
      show: ertMember
    },
    {
      icon: <AltRouteIcon />,
      name: "TRRTS",
      link: "/community/technicalqueries",
      show: trrtMember
    },
    {
      icon: <BeenhereIcon />,
      name: "TECHNICAL DECISIONS",
      link: "/community/technical-decisions",
      show: role?.role_permissions?.find(permission => permission.area === 'Technical Decisions')?.read
    },
    // {
    //   icon: <FontAwesomeIcon icon={faAward} size="xl" style={{padding: 3}}/>,
    //   name: "CERT REVIEWS",
    //   link: "/community/nist",
    //   show: ncertMember,
    // },
  ]

  const dashboardOptions = role?.role_type === 'Lab' ? cctlDashboardOptions : 
  role?.role_type === 'NIAP' ? niapDashboardOptions :
  role?.role_type === 'Validator' ? 
  validatorDashboardOptions : role?.role_type === 'SME' ? 
  smeDashboardOptions : null;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    width: drawerWidth,
    minHeight: `${drawerHeight}px`,
    flexShrink: 0,
    height: '100%',
    whiteSpace: "no-wrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const sidebarHeader = () => {
    if(role?.role_type === 'Lab'){
      setSelectedDashBoard('CCTL')
    } else {
      setSelectedDashBoard(role?.role_type)
    }
  }

  const sideBarTitle = () => {
    if(role?.role_type === 'Lab'){
      return 'CCTL'
    } else if (role?.role_type === 'SME'){
      return 'MY'
    } else {
      return role?.role_type
    }
  }

  const handleQuickLinksClick = () => {
    setOpenQuickLinks(!openQuickLinks);
    if(!sidebarOpen){
      setSidebarOpen(true)
    }
  }

  const niapQuickLinks = () => {
    return (
      <Tooltip title="QUICK LINKS">
      <ListItem
        disablePadding={true}
        className="remove-padding"
        sx={{ display: "block" }}
      >
        <ListItemButton
          onClick={handleQuickLinksClick}
          sx={{
            minHeight: windowSize.innerWidth > 960 ? 110 : 72,
            justifyContent: openQuickLinks ? "initial" : "center",
            px: 2.5
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebarOpen ? 3 : -3,
              justifyContent: "center",
            }}
            style={{ color: "var(--bs-white)" }}
          >
            <LinkIcon />
          </ListItemIcon>
          <ListItemText
            primary='QUICK LINKS'
            style={{ color: "var(--bs-white)" }}
            sx={{ opacity: sidebarOpen ? 1 : 0 }}
          />
          {sidebarOpen && 
            (openQuickLinks ? 
            <ExpandLess style={{ color: "var(--bs-white)" }} onClick={() => setOpenQuickLinks(false)} /> 
            : 
            <ExpandMore style={{ color: "var(--bs-white)" }} onClick={() => setOpenQuickLinks(true)}/>
          )}
        </ListItemButton>
        {openQuickLinks &&
          <Collapse in={sidebarOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/community/external-links" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Approved External Links</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/events-manager" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Events Manager</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/announcements-manager" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Announcements Manager</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/glossary-manager" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Glossary Manager</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/faqs" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Frequently Asked Questions</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/labgrams-manager" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Labgrams Manager</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/policy-letters" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Policy Letters</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/document-serial-numbers" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Document Serial Numbers</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/training-center" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Training Center</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/technical-communities" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Technical Communities Manager</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/upload-atypical" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Upload Atypical File</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
              <Link to="/community/site-settings" className="text-decoration-none">
                <ListItemButton sx={{ padding: 0, pl: 4}} onClick={() => setSidebarOpen(false)}>
                  <ListItemText>
                    <p className="m-0 text-white small-font">Site Settings</p>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        }
      </ListItem>
    </Tooltip>
    )
  }

  return (
    <Drawer
      open={sidebarOpen}
      variant="permanent"
      PaperProps={{
        style: {
          position: "relative",
          backgroundColor: "var(--bs-bright-navy)",
        },
      }}
    >
      <List
        style={{ height: "100%", paddingTop: "0"}}
        sx={{
          [`& .active, & .${listItemClasses.root}:hover`]: {
            backgroundColor: "var(--bs-secondary)",
          },
        }}
      >
        <Tooltip title={sidebarOpen ? "COLLAPSE" : "EXPAND"}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 72,
                justifyContent: sidebarOpen ? "initial" : "center",
                px: 2.5,
                backgroundColor: "var(--bs-bright-navy)",
              }}
              onClick={toggleOpenDrawer}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
                style={{ color: "var(--bs-white)" }}
              >
                {sidebarOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Tooltip>
        <Tooltip title="DASHBOARD">
          <ListItem
              disablePadding={true}
              className="remove-padding"
              sx={{ display: "block" }}
              component={Link}
              to='/community'
            >
            <ListItemSecondaryAction className="p-0">
              <ListItemButton
                sx={{
                  minHeight: windowSize.innerWidth > 960 ? 110 : 72,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5
                }}
                onClick={() => {
                  if (location.pathname === '/community') {
                    window.location.reload();  // Reloads the page if the current path matches the clicked item
                  }
                  sidebarHeader(); 
                  setSidebarOpen(false)
                }}
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : -3,
                      justifyContent: "center",
                    }}
                    style={{ color: "var(--bs-white)" }}
                  >
                    <DashboardIcon />
                  </ListItemIcon>
                <ListItemText
                  primary={`${sideBarTitle()} DASHBOARD`}
                  sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  style={{ color: "var(--bs-white)" }}
                ></ListItemText>
              </ListItemButton>
              {role?.role_type === 'NIAP' &&
              <ListItemButton>
              {(role?.role_type === 'NIAP' && sidebarOpen) && 
                  (openChild ? 
                  <ExpandLess style={{ color: "var(--bs-white)" }} onClick={() => setOpenChild(false)} /> 
                  : 
                  <ExpandMore style={{ color: "var(--bs-white)" }} onClick={() => setOpenChild(true)}/>
                  )}
              </ListItemButton>
              }
              </ListItemSecondaryAction>
              {role?.role_type === 'NIAP' && 
              <Collapse in={openChild} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {setSelectedDashBoard("Lab"); setSidebarOpen(false); setOpenChild(false)}}>
                    <ListItemText style={{ color: "var(--bs-white)" }} primary="CCTL Dashboard" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {setSelectedDashBoard("Validator"); setSidebarOpen(false); setOpenChild(false)}}>
                    <ListItemText style={{ color: "var(--bs-white)" }} primary="Validator Dashboard" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {setSelectedDashBoard("Vendor"); setSidebarOpen(false); setOpenChild(false)}}>
                    <ListItemText style={{ color: "var(--bs-white)" }} primary="Vendor Dashboard" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {setSelectedDashBoard("SME"); setSidebarOpen(false); setOpenChild(false)}}>
                    <ListItemText style={{ color: "var(--bs-white)" }} primary="SME Dashboard" />
                  </ListItemButton>
                </List>
              </Collapse>}
            </ListItem>
          </Tooltip>
        {dashboardOptions?.map((option, index) => (
          <Tooltip title={option.name !== "NIAP QUICK LINKS" && option.name} key={index}>
            <ListItem
              disablePadding={true}
              className="remove-padding"
              sx={{ display: "block" }}
              component={Link}
              to={option.link}
            >
              { (option.show && option.name !== "NIAP QUICK LINKS") &&
              <ListItemButton
                sx={{
                  minHeight: 72,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === option.link ? "var(--bs-secondary)" : "var(--bs-bright-navy)",
                }}
                onClick={() => {
                  if (location.pathname === option.link) {
                    window.location.reload();  // Reloads the page if the current path matches the clicked item
                  }
                  setSidebarOpen(false);
                }}
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={{ color: "var(--bs-white)" }}
                  >
                    {option.icon}
                  </ListItemIcon>
                <ListItemText
                  primary={option.name}
                  sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  style={{ color: "var(--bs-white)" }}
                ></ListItemText>
              </ListItemButton>
              }
              {
                option.name === "NIAP QUICK LINKS" && option.show === true &&
                niapQuickLinks()
              }
            </ListItem>
          </Tooltip>
        ))}
        <div
          className={windowSize.innerWidth > 960 ? "bottom-icons-sidebar" : null}
        >
         { (role?.role_permissions?.find(permission => permission.area === 'Accounts')?.read ||
            role?.role_permissions?.find(permission => permission.area === 'Organization Members')?.read) &&
          <Tooltip title="ACCOUNTS">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={"/community/accounts"}
            >
              <ListItemButton
                onClick={() => setSidebarOpen(false)}
                sx={{
                  minHeight: windowSize.innerWidth > 960 ? 48 : 72,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/community/accounts" ? "var(--bs-secondary)" : "var(--bs-bright-navy)",
                }}
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={{ color: "var(--bs-white)" }}
                  >
                    <PersonIcon />
                  </ListItemIcon>

                <ListItemText
                  primary="ACCOUNTS"
                  sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  style={{ color: "var(--bs-white)" }}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          </Tooltip>}
          {role?.role_type === 'NIAP' && <Tooltip title="MAILING LISTS">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={"/community/mailing-lists"}
            >
              <ListItemButton
                sx={{
                  minHeight: windowSize.innerWidth > 960 ? 48 : 72,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/community/mailing-lists" ? "var(--bs-secondary)" : "var(--bs-bright-navy)",
                }}
                onClick={() => setSidebarOpen(false)}
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={{ color: "var(--bs-white)" }}
                  >
                    <ContactMailIcon />
                  </ListItemIcon>
                <ListItemText
                  primary="MAILING LISTS"
                  sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  style={{ color: "var(--bs-white)" }}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          </Tooltip>}
          <Tooltip title="SEND A MESSAGE">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={"/community/email"}
            >
              <ListItemButton
                onClick={() => setSidebarOpen(false)}
                sx={{
                  minHeight: windowSize.innerWidth > 960 ? 48 : 72,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    location.pathname === "/community/email" ? "var(--bs-secondary)" : "var(--bs-bright-navy)",
                }}
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sidebarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={{ color: "var(--bs-white)" }}
                  >
                    <MailOutlineIcon />
                  </ListItemIcon>
                <ListItemText
                  primary="SEND A MESSAGE"
                  sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  style={{ color: "var(--bs-white)" }}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </div>
      </List>
    </Drawer>
  );
}
