import axios from "axios";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import HTMLEditor from "../../../../UI/HTMLEditor";
import PackageInfoSection from "./PackageInfoSection";
import RateFormSection from "./RateFormSection";
import QuestionsFormSection from "./QuestionsFormSection";
import StatementOfProficiencySection from "./StatementOfProficiencySection";
import parse from 'html-react-parser';
import FileDownload from "js-file-download";
import { handleAlertAndMailer } from "../../../Helper/functions";


export default function ValidatorProficiencyFeedbackForm({
    show,
    handleClose,
    currentFeedbackInfo,
    product,
    viewOnly,
    setAlertInfo,
    validationReport
}) {
    const [cookies] = useCookies();
    let csrfToken = cookies["csrftoken"];
    let authToken = cookies["auth_token"];
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const currentUser = useSelector((state) => state.user.value);
    const permissions = useSelector((state) => state.role.value);
    const [enforceFocusModal, setEnforceFocusModal] = useState(true)

    useEffect(() => {
        setValidated(false);
        setFormData({});
    }, [show]);

    const handleChange = (event) => {
        if (
            event.target.type === "checkbox" &&
            (event.target.name === "requested_nvlap_follow_up" ||
                event.target.name === "nvlap_follow_up")
        ) {
            setFormData({
                ...formData,
                [event.target.name]: event.target.checked,
            });
        } else if (
            [
                "validation_team_two_weeks",
                "perform_tests",
                "miss_tds",
                "misinterpret_policy",
            ].includes(event.target.name)
        ) {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value === "true",
            });
        } else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
            });
        }
    };


    const handleSubmit = (e, is_draft) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (is_draft === false && form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            //delete fields if not applicable
            if (formData?.validation_team_two_weeks === true) {
                delete formData["validation_team_days"];
            }
            if (formData?.miss_tds === false) {
                delete formData["miss_tds_ecrs"];
            }
            if (formData?.misinterpret_policy === false) {
                delete formData["misinterpret_policy_ecrs"];
            }
            if (formData?.perform_tests === false) {
                delete formData["perform_tests_ecrs"];
            }
            if (!currentFeedbackInfo?.id) {
                axios
                    .post(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/validators/`,
                        {
                            ...formData,
                            filled_out_by: currentUser?.id,
                            product_id: product?.product_id,
                            created_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                            edited_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                            is_draft: is_draft,
                            ecr_total: currentFeedbackInfo?.counts?.all_ecrs,
                            ecrs_after_pp2: currentFeedbackInfo?.counts?.checkout_ecrs,
                            check_out_package_total: currentFeedbackInfo?.counts?.checkout_package_submissions,
                            is_resubmission: currentFeedbackInfo?.product_id?.is_resubmission
                        },
                        {
                            withCredentials: true,
                            headers: {
                                "X-CSRFToken": csrfToken,
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        if (!is_draft) {
                            saveFile();
                        }
                        handleClose();
                        handleAlertAndMailer(csrfToken, "Validator", product, "submitted");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                delete formData["product_id"];
                delete formData["filled_out_by"];
                axios
                    .put(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/validators/${currentFeedbackInfo.id}/`,
                        {
                            ...formData,
                            edited_on: new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())),
                            is_draft: is_draft,
                        },
                        {
                            withCredentials: true,
                            headers: {
                                "X-CSRFToken": csrfToken,
                                Authorization: `Token ${authToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        if (!is_draft) {
                            saveFile();
                        }
                        handleClose();
                        handleAlertAndMailer(csrfToken, "Validator", product, "updated");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        if(is_draft){
            setAlertInfo({message: "Successfully saved proficiency feedback form!", variant: "success"})
        } else {
            setAlertInfo({message: "Successfully submitted proficiency feedback form!", variant: "success"})
        }
        setValidated(true);
    };

    const saveFile = () => {
        axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/validators/save_form_pdf?product_id=${product?.product_id}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
              responseType: "blob",
            }
          )
          .then((response) => {
            FileDownload(response.data, `proficiency_feedback_pid_${product?.product_id}.pdf`);
          })
          .catch((error) => {
            console.log("Error");
          });
      };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus={enforceFocusModal}
        >
            <Form
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e, false)}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                    CCTL Proficiency Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    {/* <h5 className="text-center">
                                        To assist us in our on-going efforts to
                                        improve the quality, efficiency and
                                        effectiveness of NIAP evaluations,
                                        please take a few minutes to complete
                                        and submit this survey.
                                    </h5> */}
                                </Row>
                                <PackageInfoSection
                                    currentUser={currentUser}
                                    product={product}
                                    validationReport={validationReport}
                                    currentFeedbackInfo={currentFeedbackInfo}
                                />
                                <Row>
                                    <p className="fw-bold">
                                        STATEMENT OF PROFICIENCY
                                    </p>
                                </Row>
                                <StatementOfProficiencySection
                                    currentFeedbackInfo={currentFeedbackInfo}
                                    handleChange={handleChange}
                                    viewOnly={viewOnly}
                                />
                                <Row>
                                    <p className="fw-bold">
                                        POTENTIAL NON-CONFORMITIES
                                    </p>
                                </Row>
                                <Row>
                                    <p>
                                        Unsatisfactory participation in any
                                        NVLAP proficiency testing program result
                                        in NVLAP nonconformities that must be
                                        resolved in order to obtain initial
                                        accreditation or maintain accreditation.
                                        (See NVLAP Handbook 150-20:2006 section
                                        3.4)
                                    </p>
                                    <p>
                                        Do{" "}
                                        <span className="fw-bold text-decoration-underline">
                                            NOT
                                        </span>{" "}
                                        include proprietary information in
                                        potential non-conformity or comment
                                        descriptions:
                                    </p>
                                </Row>
                                <Row className="mb-4 px-5">
                                {viewOnly ?
                                    currentFeedbackInfo?.potential_non_conformities && parse(currentFeedbackInfo?.potential_non_conformities)
                                    :
                                    <HTMLEditor
                                        name="potential_non_conformities"
                                        handleChange={handleChange}
                                        setIsValid={() => {}}
                                        isValid={true}
                                        defaultValue={currentFeedbackInfo?.potential_non_conformities}
                                        setEnforceFocusModal={setEnforceFocusModal}
                                    />
                                }
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <p className="fw-bold">
                                PLEASE ANSWER THE FOLLOWING QUESTIONS TO THE
                                BEST OF YOUR ABILITY:
                            </p>
                        </Row>
                        <QuestionsFormSection
                            formData={formData}
                            currentFeedbackInfo={currentFeedbackInfo}
                            handleChange={handleChange}
                            viewOnly={viewOnly}
                        />
                        <Row>
                            <p className="fw-bold">
                                PLEASE RATE THE FOLLOWING QUESTIONS
                            </p>
                        </Row>
                        <RateFormSection
                            currentFeedbackInfo={currentFeedbackInfo}
                            handleChange={handleChange}
                            viewOnly={viewOnly}
                        />
                        <Row className="mt-4">
                            <p className="fw-bold">COMMENTS:</p>
                        </Row>
                        <Row className="mb-4 px-5">
                        {viewOnly ?
                            currentFeedbackInfo?.comments && parse(currentFeedbackInfo?.comments) 
                            :
                            <HTMLEditor
                                name="comments"
                                handleChange={handleChange}
                                setIsValid={() => {}}
                                isValid={true}
                                defaultValue={currentFeedbackInfo?.comments}
                                setEnforceFocusModal={setEnforceFocusModal}
                            />
                        }
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="outline-primary" onClick={handleClose}>
                        Close
                    </Button>
                    {viewOnly &&(
                        <Button 
                        variant="success"
                        onClick={saveFile}
                        >
                            Download
                        </Button>
                    )}
                    {!viewOnly && (
                        <>
                            <Button
                                type="submit"
                                className="mx-3"
                                onClick={(e) => {
                                    handleSubmit(e, true);
                                }}
                            >
                                Save as Draft
                            </Button>
                            {(permissions?.is_senior || permissions?.is_lead) && 
                                <Button
                                    variant="success"
                                    className="mx-3"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            }
                        </>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
