import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import moment from "moment";
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from "axios";
import qs from 'qs'
import {useCookies} from "react-cookie";
import { calculateDaysInProgress } from "../../../utils/timeUtils";

export default function ACMRTable({ acmrProducts, tableRef }) {
  const [cookies] = useCookies()
  let authToken = cookies['auth_token']

  const daysPassed = (assuranceMaintenance) => {
    if (assuranceMaintenance) {
      const { iar_received: startDate, completion_date } = assuranceMaintenance;
      const endDate = completion_date ? completion_date : new Date();
      return calculateDaysInProgress(startDate, endDate);
    } else {
      return 0;
    }
  };


  const acmrColumns = [
    {
      title: "Submitted On",
      render: (rowData) => {
        if(rowData?.current_assurance_maintenance.lab_request_date){
          return moment.utc(rowData?.current_assurance_maintenance.lab_request_date).format("MM/DD/YYYY")
        } else if (rowData?.current_assurance_maintenance.vendor_request_date){
          return moment.utc(rowData?.current_assurance_maintenance.vendor_request_date).format("MM/DD/YYYY")
        } else {
          return <>No Date</>
        }
      }

    },
    {
      title: "VID",
      render: (rowData) => rowData.v_id,
    },
    {
      title: "Product Name",
      render: (rowData) => rowData.product_name,
    },
    {
      title: "CCTL",
      render: (rowData) => rowData.assigned_lab.name,
    },
    {
      title: "Requested By",
      render: (rowData) => rowData.vendor_id.name,
    },
    {
      title: "Due Date",
      field: "am_estimated_date",
      render: (rowData) => (
        rowData.current_assurance_maintenance?.team_due_date ? 
        moment.utc(rowData.current_assurance_maintenance?.team_due_date).format("MM/DD/YYYY")
        :
        <>No Date Set</>
      )
    },
    {
      title: "Status",
      render: (rowData) => rowData.status,
    },
    {
      title: "Days in AM",
      render: (rowData) => daysPassed(rowData?.current_assurance_maintenance),
    },
    { title: 'Actions', field: 'actions', filtering: false, sorting: false, render: rowData => (
        <Link 
        to={`/community/products/details/${rowData?.product_id}/assurancemaintenance/${rowData.current_assurance_maintenance?.maintenance_id}`} 
        className='text-decoration-none'>
          <Button variant='link' className='m-1 p-0 btn-link'><VisibilityIcon color="action" /> View</Button>
      </Link>       
    )}
   
  ];


  const options = {
    sorting: true,
    columnsButton: true,
  };

  return (
    <>
      <ResponsiveMaterialTable
        title="ACMR's"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
            } = query;

            let newFilters = filters.map((filter) => {
                let value = ""
                if (Array.isArray(filter.value)) {
                    value = filter.value
                } else {
                    value = filter.value.replace(/['"]+/g, '')
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
            }
            axios
                .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/niap_acmr/?${qs.stringify(params, {arrayFormat: 'comma'})}`,
                    {
                        withCredentials: true,
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    }
                )
                .then((response) => {
                    resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                    });
                })
                .catch((error) => {
                    reject(error);
                });
            })
          }
        options={options}
        columns={acmrColumns}
      />
      
    </>
  );
}
