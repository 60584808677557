import React from "react";
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

function JumpVID({ setShowAlert, setAlertText }) {

    const [cookies] = useCookies();
    const navigate = useNavigate()
    let authToken = cookies["auth_token"];
    const [vidId, setVidId] = useState("")

    const jump = (e) => {
        e.preventDefault()
        axios.get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_product_by_vid/?vid=${vidId}`,
            {
                withCredentials: true,
                headers: { Authorization: `Token ${authToken}` },
            }).then(response => {
                navigate(`/community/products/details/${response?.data?.product_id}`)
                setShowAlert(false)
                setAlertText("")
                setVidId("")
            }).catch((error) => {
                setShowAlert(true)
                console.log(error)
                setAlertText(error.response.data.error)
                setVidId("")
            })
    }
    return (
        <Form onSubmit={jump}>
            <Row className="justify-content-end">
                <Col xs={12} md={3}>
                    <Form.Group controlId="jumpbox">
                        <Form.Label className="visually-hidden">Jump to VID</Form.Label>
                        <Form.Control value={vidId} name="vid" onChange={(e) => setVidId(e.target.value)} placeholder="Jump to VID" />
                    </Form.Group>
                </Col>
                <Col xs={12} md="auto">
                    <Button type="submit" className="w-100 w-md-auto mt-2 mt-md-0">Jump</Button>
                </Col>
            </Row>
        </Form>
    )

}

export default JumpVID;